import { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Popconfirm, Tag } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, FileDoneOutlined, MailOutlined, PlayCircleOutlined } from '@ant-design/icons'

import { detailsApi } from '@src/store/services/details-service'
import { TaskStatusEnum } from '@src/types/tasks'
import { PageTable } from '@src/components/Table/PageTable'
import useQuery from '@src/hooks/useQuery'
import { isSelectionText } from '@src/lib/utils'
import { setSendEmail } from '@src/store/ducks/app/reducer'
import { SendEmailType } from '@src/types/mailLogs'
import { AppDispatch } from '@src/store/store'
import { REQUEST_STATUSES, SendStatusDetailsOffersType, DetailOfferType } from '@src/types/details'
import { formatPrice } from '@src/lib/formatPrice'
import cn from 'classnames'

export const DetailsOffers = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const pageQuery = useQuery('page')
  const dispatch = useDispatch<AppDispatch>()
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
    filters: {
      status: {
        $eq: TaskStatusEnum.New,
      },
    },
  })
  const { data, isLoading } = detailsApi.useGetDetailsOffersQuery(paramsFilters)
  const [onDelete, { isLoading: isLoadingDelete }] = detailsApi.useDeleteDetailOfferMutation()
  const [update] = detailsApi.useUpdateDetailOffersMutation()
  const [generateDocument] = detailsApi.useGenerateDocumentDetailOffersMutation()

  const onCell = useCallback((record: DetailOfferType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/details-offers/edit/${record.id}`)
      }
    },
  }), [history])

  const handleUpdate = useCallback((id: number, status: SendStatusDetailsOffersType) => {
    update({ id, status })
  }, [update])

  const handleCreateInvoice = useCallback((record: DetailOfferType) => {
    history.push({
      pathname: 'details-invoices/create',
      state: record,
    })
  }, [history])

  const columns = useMemo<ColumnProps<any>[]>(() => [
    {
      title: t('detailsOffers.columns.number'),
      dataIndex: 'number',
      key: 'number',
      onCell,
    },
    {
      title: t('detailsOffers.columns.company'),
      dataIndex: 'company',
      key: 'company',
      render: (company) => company?.name,
      onCell,
    },
    {
      title: t('detailsOffers.columns.date'),
      dataIndex: 'date',
      key: 'date',
      onCell,
    },
    {
      title: t('detailsOffers.columns.price'),
      dataIndex: 'details',
      key: 'price',
      render: (details) => {
        const totalPrice = details.reduce((acc: any, detail: any) => {
          const price = detail.pivot?.price || 0
          const count = detail.pivot?.count || 1
          return acc + price * count
        }, null)
        return totalPrice && formatPrice(totalPrice)
      },
      onCell,
    },
    {
      title: t('detailsOffers.columns.status.title'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        const TAG_COLOR = {
          [REQUEST_STATUSES.Created]: 'warning',
          [REQUEST_STATUSES.Accepted]: 'success',
          [REQUEST_STATUSES.Rejected]: 'error',
          [REQUEST_STATUSES.Closed]: 'default',
        }
        return <Tag style={{ textTransform: 'capitalize' }} color={TAG_COLOR[status as (typeof REQUEST_STATUSES)[keyof typeof REQUEST_STATUSES]]}>{t(`detailsOffers.columns.status.${status}`)}</Tag>
      },
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      render: (_, record) => (
        <div className="table_actions">
          {record.status === SendStatusDetailsOffersType.created && (
            <button
              type="button"
              className="table_actions_item"
              onClick={() => {
                dispatch(setSendEmail({
                  type: SendEmailType.DetailsOffers,
                  companyId: record.company_id,
                  id: record.id,
                }))
              }}
            >
              <MailOutlined />
            </button>
          )}
          {record.status === SendStatusDetailsOffersType.pending && (
            <>
              <Popconfirm
                placement="left"
                title={t('popconfirm.accept')}
                onConfirm={() => {
                  handleUpdate(record.id, SendStatusDetailsOffersType.accepted)
                }}
                okText={t('popconfirm.okText')}
                cancelText={t('popconfirm.cancelText')}
                disabled={isLoadingDelete}
              >
                <button
                  type="button"
                  className="table_actions_item"
                >
                  <CheckCircleOutlined />
                </button>
              </Popconfirm>
              <Popconfirm
                placement="left"
                title={t('popconfirm.reject')}
                onConfirm={() => {
                  handleUpdate(record.id, SendStatusDetailsOffersType.rejected)
                }}
                okText={t('popconfirm.okText')}
                cancelText={t('popconfirm.cancelText')}
                disabled={isLoadingDelete}
              >
                <button
                  type="button"
                  className="table_actions_item"
                >
                  <CloseCircleOutlined />
                </button>
              </Popconfirm>
            </>
          )}
          {record.status === SendStatusDetailsOffersType.accepted && (
            <button
              onClick={() => handleCreateInvoice(record)}
              type="button"
              className="table_actions_item"
            >
              <PlayCircleOutlined />
            </button>
          )}
          <button
            onClick={() => generateDocument(record.id)}
            type="button"
            className="table_actions_item"
          >
            <FileDoneOutlined />
          </button>
          <Popconfirm
            placement="left"
            title={t('popconfirm.deleteTitle')}
            onConfirm={() => {
              onDelete(record.id)
            }}
            okText={t('popconfirm.okText')}
            cancelText={t('popconfirm.cancelText')}
            disabled={isLoadingDelete}
          >
            <button
              type="button"
              className={cn('table_actions_item', 'table_actions_item__delete')}
              disabled={isLoadingDelete}
            >
              <DeleteOutlined />
            </button>
          </Popconfirm>
        </div>
      ),
    },
  ], [dispatch, generateDocument, handleCreateInvoice, handleUpdate, isLoadingDelete, onCell, onDelete, t])

  return (
    <PageTable
      title={t('detailsOffers.title')!}
      createLink="/details-offers/create"
      setParamsFilters={setParamsFilters}
      paramsFilters={paramsFilters}
      tableData={{
        ...data,
        isLoading,
        columns,
      }}
    />
  )
}
