import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { InquiriesDataType } from '@src/types/inquaries'
import { GetItemsType } from '@src/api/api'
import { formatFilters } from '@src/lib/formatFilters'

export const inquiriesApi = createApi({
  reducerPath: 'inquiriesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      headers.set('Accept', 'application/json, text/plain, */*')
      return headers
    },
  }),
  refetchOnMountOrArgChange: 60,
  tagTypes: ['Inquiries'],
  endpoints: (build) => ({
    list: build.query<GetItemsType<InquiriesDataType>, any>({
      query: (params) => ({
        url: `/inquiries?${formatFilters(params)}`,
      }),
      providesTags: () => ['Inquiries'],
    }),
    addInquiries: build.mutation<void, { body: any }>({
      query: ({ body }) => ({
        url: '/inquiries',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Inquiries'],
    }),
  }),
})
