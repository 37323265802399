import { useTranslation } from 'react-i18next'
import React, { FC, forwardRef, useImperativeHandle, useMemo, useState } from 'react'
import { tasksApi } from '@src/store/services/tasks-service'
import { Button, Drawer, Popconfirm, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { LastWorkTimelogType } from '@src/types/tasks'
import moment from 'moment'
import { DeleteOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons'
import { calculateTimeUntil } from '@src/lib/calculateTimeUntil'
import { AllowedTo } from '@src/components/AllowedTo/AllowedTo'
import { UserRoles } from '@src/types/users'
import { Form, Formik } from 'formik'
import { DatePickerFormik } from '@src/components/DatePickerFormik/DatePickerFormik'
import cn from 'classnames'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'

type TaskTimelogsProps = {
  taskId: number
  workers: Array<{ id: number, name: string }>
  ref?: any
}

export const TaskTimelogs: FC<TaskTimelogsProps> = forwardRef(({ taskId, workers }, ref) => {
  const { t } = useTranslation()
  const { data } = tasksApi.useGetTimelogsHistoryQuery(taskId)
  const [createTimelog, { isLoading: isLoadingCreateTimelog }] = tasksApi.useCreateTimelogMutation()
  const [editTimelog, { isLoading: isLoadingEditTimelog }] = tasksApi.useEditTimelogMutation()
  const [deleteTimelog, { isLoading: isLoadingDeleteTimelog }] = tasksApi.useDeleteTimelogMutation()
  const [isOpen, setIsOpen] = useState(false)
  const [editId, setEditId] = useState<number | null>(null)

  const columns = useMemo<ColumnProps<LastWorkTimelogType>[]>(
    () => [
      {
        title: t('tasks.timelogs.mechanic'),
        dataIndex: 'mechanic',
        key: 'mechanic',
        render: (mechanic) => `${mechanic.name} ${mechanic.surname}`,
      },
      {
        title: t('tasks.timelogs.started'),
        dataIndex: 'started',
        key: 'started',
        render: (started) => moment(started).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: t('tasks.timelogs.ended'),
        dataIndex: 'ended',
        key: 'ended',
        render: (ended) => (
          ended ? moment(ended).format('YYYY-MM-DD HH:mm:ss') : <LoadingOutlined />
        ),
      },
      {
        title: t('tasks.timelogs.time'),
        dataIndex: 'time',
        key: 'time',
        render: (_, record) => `${!record.ended ? '>' : ''}${calculateTimeUntil(record.started, record.ended)}`,
      },
      {
        dataIndex: 'actions',
        key: 'actions',
        fixed: 'right',
        render: (_, record) => (
          <div className="table_actions">
            <AllowedTo roles={[UserRoles.ServiceManager, UserRoles.Admin]}>
              <button
                type="button"
                onClick={() => {
                  setEditId(record.id)
                  setIsOpen(true)
                }}
              >
                <EditOutlined />
              </button>
              <Popconfirm
                placement="left"
                title={t('popconfirm.deleteTitle')}
                onConfirm={() => {
                  deleteTimelog(record.id)
                }}
                okText={t('popconfirm.okText')}
                cancelText={t('popconfirm.cancelText')}
                disabled={isLoadingDeleteTimelog}
              >
                <button
                  type="button"
                  className={cn('table_actions_item', 'table_actions_item__delete')}
                  disabled={isLoadingDeleteTimelog}
                >
                  <DeleteOutlined />
                </button>
              </Popconfirm>
            </AllowedTo>
          </div>
        ),
      },
    ],
    [deleteTimelog, isLoadingDeleteTimelog, t],
  )

  const onClose = () => {
    setIsOpen(false)
    setEditId(null)
  }

  const initialValuesEdit = useMemo(() => {
    const timelog = data?.find((item) => item.id === editId)

    if (editId && !timelog) {
      onClose()

      return {
        id: editId,
        started: '',
        ended: '',
      }
    }

    if (!editId) {
      return {
        task_id: taskId,
        user_id: '',
        started: '',
        ended: '',
      }
    }

    return {
      id: editId,
      user_id: timelog!.user_id,
      started: moment(timelog!.started),
      ended: timelog!.ended ? moment(timelog!.ended) : '',
    }
  }, [data, editId, taskId])

  const onSubmit = async (values: any) => {
    let result
    if (editId) {
      result = await editTimelog({
        ...values,
        started: values.started.format('YYYY-MM-DD HH:mm:ss'),
        ended: values.ended?.format('YYYY-MM-DD HH:mm:ss'),
      })
    } else {
      result = await createTimelog({
        ...values,
        started: values.started.format('YYYY-MM-DD HH:mm:ss'),
        ended: values.ended?.format('YYYY-MM-DD HH:mm:ss'),
      })
    }

    if ('data' in result) {
      onClose()
    }
  }

  useImperativeHandle(ref, () => ({
    setIsOpenCreate: () => setIsOpen(true),
  }))

  return (
    <div>
      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        rowKey="id"
      />
      <Drawer open={isOpen} onClose={onClose}>
        <Formik initialValues={initialValuesEdit} onSubmit={onSubmit} enableReinitialize>
          <Form className="not-card">
            <SelectFormik
              name="user_id"
              options={workers.map((item) => ({ value: item.id, label: item.name }))}
              placeholder={t('tasks.timelogs.mechanic')}
            />
            <DatePickerFormik
              name="started"
              placeholder={t('tasks.timelogs.started')}
              showTime
              format="YYYY-MM-DD HH:mm:ss"
            />
            <DatePickerFormik
              name="ended"
              placeholder={t('tasks.timelogs.ended')}
              showTime
              format="YYYY-MM-DD HH:mm:ss"
            />
            <Button htmlType="submit" type="primary" loading={isLoadingEditTimelog || isLoadingCreateTimelog}>
              Save
            </Button>
          </Form>
        </Formik>
      </Drawer>
    </div>
  )
})
