import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@src/store/store'
import { serviceInvoicesApi } from '@src/store/services/serviceInvoices-service'
import { ColumnProps } from 'antd/lib/table'
import { ServiceInvoiceType } from '@src/types/serviceInvoices'
import { PageTable } from '@src/components/Table/PageTable'
import { TableFiltersType } from '@src/types/tableFilters'
import { setSendEmail } from '@src/store/ducks/app/reducer'
import { SendEmailType } from '@src/types/mailLogs'
import { FileDoneOutlined, MailOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { carsApi } from '@src/store/services/cars-service'
import { companiesApi } from '@src/store/services/companies-service'
import useQuery from '@src/hooks/useQuery'
import { addQuery } from '@src/lib/route'
import { isSelectionText } from '@src/lib/utils'

export const ServiceInvoices = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch<AppDispatch>()
  const pageQuery = useQuery('page')
  const filtersQuery = useQuery('filters')
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
    filters: filtersQuery ? JSON.parse(filtersQuery) : {},
  })
  const { data, isLoading } = serviceInvoicesApi.useGetListServiceInvoicesQuery(paramsFilters)
  const { data: carsSelect } = carsApi.useGetCarsForSelectQuery({})
  const { data: clientsSelect } = companiesApi.useGetSelectCompaniesQuery()
  const [generateDocument] = serviceInvoicesApi.useGenerateDocumentServiceInvoiceMutation()

  useEffect(() => {
    history.push({
      search: addQuery({ ...paramsFilters, filters: JSON.stringify(paramsFilters.filters) }),
    })
  }, [paramsFilters, history])

  const onCell = useCallback((record: ServiceInvoiceType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/service-invoices/edit/${record.id}`)
      }
    },
  }), [history])

  const columns = useMemo<ColumnProps<ServiceInvoiceType>[]>(() => [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => (index + 1 + ((paramsFilters.page - 1) * (data?.meta.pageSize || 0))),
      onCell,
    },
    {
      title: t('serviceInvoices.columns.number'),
      dataIndex: 'number',
      key: 'number',
      onCell,
    },
    {
      title: t('tasks.columns.client'),
      dataIndex: 'task',
      key: 'client',
      render: (task) => task.client.name,
      onCell,
    },
    {
      title: t('tasks.columns.car'),
      dataIndex: 'task',
      key: 'car',
      render: (task) => `${task.car.make}/${task.car.model}`,
      onCell,
    },
    {
      title: t('tasks.columns.name'),
      dataIndex: 'task',
      key: 'name',
      render: (task) => task.name,
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <div className="table_actions">
          <button
            type="button"
            onClick={() => {
              dispatch(setSendEmail({
                type: SendEmailType.ServiceInvoice,
                companyId: record.task.client_id,
                id: record.id,
              }))
            }}
            className="table_actions_item"
          >
            <MailOutlined />
          </button>
          <button
            onClick={() => generateDocument(record.id)}
            type="button"
            className="table_actions_item"
          >
            <FileDoneOutlined />
          </button>
        </div>
      ),
    },
  ], [onCell, t, paramsFilters.page, data?.meta.pageSize, dispatch, generateDocument])

  const filtersList = useMemo<TableFiltersType>(() => [
    {
      type: 'input',
      dataIndex: 'task.name',
      placeholder: t('tasks.columns.name'),
      operator: '$contains',
      width: '200px',
    },
    {
      type: 'input',
      dataIndex: 'number',
      placeholder: t('serviceInvoices.columns.number'),
      operator: '$contains',
      width: '200px',
    },
    {
      type: 'select',
      dataIndex: 'task.client_id',
      placeholder: t('tasks.columns.client'),
      operator: '$eq',
      options: clientsSelect?.map((item) => ({ value: item.id, label: item.name })) || [],
      width: '200px',
    },
    {
      type: 'select',
      dataIndex: 'task.car_id',
      placeholder: t('tasks.columns.car'),
      operator: '$eq',
      options: carsSelect?.map((item) => ({ value: item.id, label: item.name })) || [],
      width: '200px',
    },
  ], [carsSelect, clientsSelect, t])

  return (
    <div>
      <PageTable
        title={t('serviceInvoices.title')}
        setParamsFilters={setParamsFilters}
        filtersList={filtersList}
        filtersV2
        paramsFilters={paramsFilters}
        tableData={{
          ...data,
          columns,
          isLoading,
        }}
      />
    </div>
  )
}
