import { Fragment, useMemo } from 'react'
import { Button, Col, Row } from 'antd'
import { Form, Formik } from 'formik'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { serialize } from 'object-to-formdata'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { Preloader } from '@src/components/Preloader/Preloader'
import { detailsApi } from '@src/store/services/details-service'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import { DetailUnitEnum } from '@src/types/details'
import { stocksApi } from '@src/store/services/stocks-service'
import { UploadImages } from '@src/components/UploadImages/UploadImages'
import { Image } from '@src/components/ImagesPreview/Image'
import style from '@src/components/pages/cars/carViewContent.module.scss'
import useQuery from '@src/hooks/useQuery'

export const DetailsCreate = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams<{ id?: string }>()
  const serialNumberQuery = useQuery('serialNumber')
  const { data, isLoading: isLoadingGetOne } = detailsApi.useGetOneDetailQuery(+params.id!, { skip: !params.id })
  const [create, { isLoading: isLoadingCreate }] = detailsApi.useCreateDetailMutation()
  const [edit, { isLoading: isLoadingEdit }] = detailsApi.useEditDetailMutation()
  const { data: stockAreas } = stocksApi.useGetStockAreasShortListQuery()
  const [deleteImage] = detailsApi.useDeleteDetailsImageMutation()

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      name: Yup.string().required(t('form.errors.required') ?? ''),
      serial_numbers: Yup.array()
        .of(Yup.object().shape({
          serial_number: Yup.string().required(t('form.errors.required')),
        }))
        .min(1)
        .required(t('form.errors.required')),
      unit: Yup.string().required(t('form.errors.required') ?? ''),
      vat: Yup.string().required(t('form.errors.required') ?? ''),
      stock_area_id: Yup.string().required(t('form.errors.required') ?? ''),
    })
  ), [t])
  console.log(params)
  const initialValues = useMemo(() => ({
    id: data?.id || null,
    name: data?.name || '',
    serial_numbers: (serialNumberQuery && [{ serial_number: serialNumberQuery }]) || data?.serial_numbers || [{ serial_number: '' }],
    unit: data?.unit || '',
    price: data?.price || '0',
    vat: data?.vat || '21',
    stock_area_id: data?.stock_area_id || '',
    images: [],
  }), [data, serialNumberQuery])

  const onSubmit = async (values: any) => {
    const body = {
      ...values,
      price: values.price || '0',
      images: values.images.map((image: any) => ({ file: image.originFileObj })),
    }
    const formData = serialize(body, { indices: true })
    let result
    if (params.id) {
      result = await edit({
        formData,
        id: values.id,
      })
    } else {
      result = await create(formData)
    }

    if ('data' in result) {
      history.push(`/details/view/${result.data.id}`)
    }
  }

  return (
    <div>
      <Preloader loading={isLoadingGetOne}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Row gutter={[20, 0]}>
                <Col xs={24} md={12}>
                  <FieldFormik name="name" placeholder={t('details.columns.name') ?? ''} />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik name="price" placeholder={t('details.columns.price') ?? ''} type="number" />
                </Col>
                <Col xs={24} md={12}>
                  <SelectFormik
                    name="stock_area_id"
                    placeholder={t('details.columns.stockArea') ?? ''}
                    options={stockAreas?.map((item) => ({ value: item.id, label: item.name })) || []}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <SelectFormik
                    name="unit"
                    options={Object.values(DetailUnitEnum).map((item) => ({ value: item, label: item }))}
                    placeholder={t('details.columns.unit') ?? ''}
                  />
                </Col>
                {values.serial_numbers.map((_, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Fragment key={i}>
                    <Col span={22}>
                      <FieldFormik name={`serial_numbers[${i}].serial_number`} placeholder={t('details.columns.serialNumber') ?? ''} />
                    </Col>
                    <Col span={2}>
                      {values.serial_numbers.length > 1 && (
                      <Button
                        size="large"
                        onClick={() => {
                          setFieldValue('serial_numbers', values.serial_numbers.filter((__: any, filterI: number) => filterI !== i))
                        }}
                      >
                        -
                      </Button>
                      )}
                    </Col>
                  </Fragment>
                ))}
                <Col span={24}>
                  <Button onClick={() => { setFieldValue('serial_numbers', [...values.serial_numbers, { serial_number: '' }]) }}>
                    {t('details.addSerialNumber')}
                  </Button>
                </Col>
              </Row>
              <br />
              <div className={style.gallery}>
                {data?.images.map((item) => (
                  <Image
                    url={`${process.env.REACT_APP_API_URL}/${item.image}`}
                    thumb={`${process.env.REACT_APP_API_URL}/${item.thumb}`}
                    onImageDelete={() => { deleteImage(item.id) }}
                  />
                ))}
              </div>
              <br />
              <div>
                <UploadImages
                  fileList={values.images}
                  setFileList={(image) => {
                    setFieldValue('images', image)
                  }}
                />
              </div>

              <Button htmlType="submit" type="primary" loading={isLoadingCreate || isLoadingEdit}>
                {params.id ? t('details.save') : t('details.submit')}
              </Button>
            </Form>
          )}
        </Formik>
        <br />
        {/* <Card>
          123
        </Card> */}
      </Preloader>
    </div>
  )
}
