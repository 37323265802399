import { FC, ReactNode, useMemo } from 'react'
import { useCarsViewContentListHook } from '@src/components/pages/cars/CarsViewContentList.hook'
import { CarImages } from '@src/types/car'
import { Collapse } from 'antd'
import { Image } from '@src/components/ImagesPreview/Image'
import style from './carViewContent.module.scss'

type CarViewContentProps = {
  activeTabKey: string
  serialNumber: string
  id: number
  images: CarImages
}

export const CarViewContent: FC<CarViewContentProps> = ({ activeTabKey, serialNumber, images, id }) => {
  const contentList = useCarsViewContentListHook(serialNumber!, id)

  const contentListRender = useMemo<Record<string, ReactNode>>(() => ({
    ...contentList,
    gallery: (
      <Collapse accordion>
        {Object.keys(images).map((key) => (
          <Collapse.Panel header={key} key={key}>
            <div className={style.gallery}>
              {images[key].map((item) => (
                <Image
                  url={`${process.env.REACT_APP_API_URL}/${item.image}`}
                  thumb={`${process.env.REACT_APP_API_URL}/${item.thumb}`}
                />
              ))}
            </div>
          </Collapse.Panel>
        ))}
      </Collapse>
    ),
  }), [contentList, images])

  return (
    <div>
      {contentListRender[activeTabKey]}
    </div>
  )
}
