import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Col, Descriptions, Row } from 'antd'
import { NavLink, useParams } from 'react-router-dom'
import moment from 'moment'
import { EditOutlined } from '@ant-design/icons'
import { carsApi } from '@src/store/services/cars-service'
import { Column } from '@ant-design/charts'
import { configColumn } from '@src/components/Statistics/config'
import { CarViewContent } from '@src/components/pages/cars/CarViewСontent'

export const CarsView = () => {
  const { t } = useTranslation()
  const params = useParams<{ id: string }>()
  const [activeTabKey, setActiveTabKey] = useState<string>('agreementOrders')
  const { data, isLoading } = carsApi.useGetOneCarQuery(+params.id)

  return (
    <div>
      <Row gutter={[12, 12]}>
        <Col lg={24} xl={8}>
          <Card
            loading={isLoading}
            actions={[
              <NavLink to={`/cars/edit/${params.id}`}>
                <EditOutlined key="edit" />
              </NavLink>,
            ]}
          >
            {data && (
              <>
                <Descriptions
                  title={`${data.make}/${data.model}`}
                  column={1}
                  items={[
                    { label: t('cars.columns.serialNumber'), children: data.serial_number },
                    { label: t('cars.columns.year'), children: data.year },
                    { label: t('cars.columns.engineHours'), children: data.engine_hours },
                    { label: t('cars.columns.createdAt'), children: moment(data.created_at).format('YYYY-MM-DD') },
                    { label: t('cars.columns.ownerName'), children: data.owner?.name },
                    { label: t('cars.columns.fromService'), children: data.from_service ? t('common.true') : t('common.false') },
                    { label: t('cars.columns.paidOnce'), children: data.paid_once ? t('common.true') : t('common.false') },
                  ]}
                />
                <Column
                  {...configColumn}
                  xField="month"
                  height={250}
                  isGroup={false}
                  seriesField=""
                  data={[
                    { month: '2023-03', value: 20000 },
                    { month: '2023-04', value: 48400 },
                    { month: '2023-05', value: 30000 },
                    { month: '2023-06', value: 20000 },
                    { month: '2023-07', value: 48400.67 },
                    { month: '2023-08', value: 20000 },
                    { month: '2023-09', value: 48400.67 },
                    { month: '2023-10', value: 20000 },
                    { month: '2023-11', value: 48400.67 },
                    { month: '2023-12', value: 20000 },
                    { month: '2024-01', value: 48400.67 },
                    { month: '2024-02', value: 20000 },
                  ]}
                />
              </>
            )}
          </Card>
        </Col>
        <Col lg={24} xl={16}>
          <Card
            loading={isLoading}
            activeTabKey={activeTabKey}
            onTabChange={setActiveTabKey}
            tabList={[
              {
                key: 'agreementOrders',
                label: t('menu.agreementOrders'),
              },
              {
                key: 'agreementInvoices',
                label: t('agreementInvoices.title'),
              },
              {
                key: 'gallery',
                label: t('cars.columns.gallery'),
              },
            ]}
            tabProps={{
              size: 'middle',
            }}
          >
            {data && (
              <CarViewContent
                activeTabKey={activeTabKey}
                serialNumber={data.serial_number}
                images={data.images}
                id={data.id}
              />
            )}
          </Card>
        </Col>
      </Row>
    </div>
  )
}
