export const quizData = [
  {
    title: 'Tepimo taškų ir sistemos patikra',
    children: [
      {
        name: 'spotLubricationLoadLiftingDevices',
        placeholder: 'Krovinio kėlimo įtaisų taškinis tepimas',
        options: ['Tepama', 'Netepama'],
      },
      {
        name: 'lubricationCardanDrive',
        placeholder: 'Kardaninės pavaros tepimas',
        options: ['Tepama', 'Netepama'],
      },
      {
        name: 'fillingCentralLubricationSystem',
        placeholder: 'Centrinio tepimo sistemos užpildymas',
        options: ['Tepama', 'Netepama'],
      },
    ],
  },
  {
    title: 'Techninių skysčių patikra',
    children: [
      {
        name: 'engineOilLevel',
        placeholder: 'Variklio alyvos lygis',
        options: ['Tinkamas', 'Netinkamas'],
      },
      {
        name: 'transmissionOilLevel',
        placeholder: 'Transmisinės alyvos lygis',
        options: ['Tinkamas', 'Netinkamas'],
      },
      {
        name: 'coolantLevel',
        placeholder: 'Aušinimo skysčio lygis',
        options: ['Tinkamas', 'Netinkamas'],
      },
      {
        name: 'hydraulicOilLevel',
        placeholder: 'Hidraulinės alyvos lygis',
        options: ['Tinkamas', 'Netinkamas'],
      },
    ],
  },
  {
    title: 'Kėbulo elementų patikra',
    children: [
      {
        name: 'cabinStructure',
        placeholder: 'Kabinos konstrukcija',
        options: ['Nepažeista', 'Pažeista'],
      },
      {
        name: 'lightingElements',
        placeholder: 'Apšvietimo elementai',
        options: ['Nepažeista', 'Pažeista'],
      },
      {
        name: 'interiorDetails',
        placeholder: 'Salono detalės',
        options: ['Nepažeista', 'Pažeista'],
      },
      {
        name: 'windows',
        placeholder: 'Stiklai',
        options: ['Nepažeista', 'Pažeista'],
      },
      {
        name: 'safetyElements',
        placeholder: 'Saugos elementai',
        options: ['Nepažeista', 'Pažeista'],
      },
    ],
  },
  {
    title: 'Važiuoklės patikra',
    children: [
      {
        name: 'tireCondition',
        placeholder: 'Padangų būklė',
        options: ['Tinkama', 'Netinkama'],
      },
      {
        name: 'steeringGearSystemCondition',
        placeholder: 'Vikšrinės pavaros sistemos būklė',
        options: ['Tinkama', 'Netinkama'],
      },
    ],
  },
  {
    title: 'Techninių skysčių nuotekis',
    children: [
      {
        name: 'technicalFluidLeaks',
        placeholder: '',
        options: ['Nepastebėta', 'Pastebėta'],
      },
    ],
  },
  {
    title: 'Kaušo peilio būklė',
    children: [
      {
        name: 'bucketBladeCondition',
        placeholder: '',
        options: ['Gera', 'Bloga'],
      },
    ],
  },
  {
    title: 'Laisvumas technikos elementų sujungimo vietose',
    children: [
      {
        name: 'freedomOfJointsInMachineComponents',
        placeholder: '',
        options: ['Nepastebėta', 'Pastebėta'],
      },
    ],
  },
]
