export const getLithuanianName = (termType: string) => {
  switch (termType) {
    case 'months':
      return 'Menuo'
    case 'days':
      return 'Diena'
    case 'hours':
      return 'Valandą'
    default:
      return termType
  }
}
