import qs from 'qs'
import lodash from 'lodash'

export const formatFilters = (params: Record<string, any>) => {
  const transformedArray = lodash.map(params.filters, (value, key) => {
    const parts = key.split(/(?<!\|)\./).map((part) => part.replace('|', ''))

    return lodash.set({}, parts, value)
  })

  const sort = params.sort?.map((item: string) =>
    item.replace('ascend', 'asc').replace('descend', 'desc'))

  return qs.stringify({
    ...params,
    filters: {
      $and: transformedArray,
    },
    sort,
  })
}
