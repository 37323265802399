import { useEffect, useState } from 'react'
import { notificationApi } from '@src/store/services/notification-service'
import { useTranslation } from 'react-i18next'
import { TeamOutlined } from '@ant-design/icons'
import { UserRoles } from '@src/types/users'
import { authApi } from '@src/store/services/auth-service'

export const NotificationTitle = () => {
  const { t } = useTranslation()
  const { data: user } = authApi.useGetMeQuery()

  const { data: readNotifications, isLoading } = notificationApi.useGetReadNotificationQuery(undefined, {
    pollingInterval: user?.role === UserRoles.SalesManager ? 6000000 : undefined,
    skip: !user || user.role !== UserRoles.SalesManager,
  })

  const [readCount, setReadCount] = useState(0)

  useEffect(() => {
    if (readNotifications) {
      const { count } = readNotifications
      setReadCount(count)
    }
  }, [readNotifications])

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <TeamOutlined />
      <span>{t('notifications.title')}</span>
      {!isLoading && (
        <span style={{ marginLeft: '60px', fontWeight: 'bold' }}>
          {readCount}
        </span>
      )}
    </div>
  )
}
