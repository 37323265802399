import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Button, Col, Drawer, Row } from 'antd'
import { Form, Formik } from 'formik'

import { Preloader } from '@src/components/Preloader/Preloader'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import { CustomerCreate } from '@src/Pages/CustomerCreate/CustomerCreate'
import { OwnerCreate } from '@src/Pages/OwnerCreate/OwnerCreate'
import { EyeOutlined } from '@ant-design/icons'
import { CustomerInfo } from '@src/components/CustomerInfo/CustomerInfo'
import { companiesApi } from '@src/store/services/companies-service'
import { CompanyType } from '@src/types/company'
import { agreementsApi } from '@src/store/services/agreements-service'
import { AgreementsCreateCard } from '@src/components/pages/agreements/AgreementsCreateCard'
import { customerWorkersApi } from '@src/store/services/customerWorkers-service'
import { DatePickerFormik } from '@src/components/DatePickerFormik/DatePickerFormik'
import moment from 'moment'

type AgreementsCreateProps = {
  isModal?: boolean
  successCallbackModal?: () => void
}

export const AgreementsCreate: FC<AgreementsCreateProps> = ({ isModal, successCallbackModal }) => {
  const formRef = useRef<any>()
  const { t } = useTranslation()
  const params = useParams<{ id?: string }>()
  const history = useHistory()
  const [isCreate, setIsCreate] = useState<boolean>(false)
  const [isShowInfo, setIsShowInfo] = useState<boolean>(false)
  const [createType, setCreateType] = useState<'car' | 'customer' | 'owner' | null>(null)
  const { data: customersSelect } = companiesApi.useGetSelectCompaniesQuery(CompanyType.Customer)
  const { data: ownersSelect } = companiesApi.useGetSelectCompaniesQuery(CompanyType.Owner)
  const [triggerOwnerRepresentative, { data: ownerRepresentative }] = customerWorkersApi.useLazyListQuery()
  const [triggerCustomerRepresentative, { data: customerRepresentative }] = customerWorkersApi.useLazyListQuery()
  const { data: info, isLoading } = agreementsApi.useGetOneAgreementQuery(+params.id!, { skip: !params.id || isModal })
  const [create, { isLoading: isLoadingCreate }] = agreementsApi.useCreateAgreementMutation()
  const [edit, { isLoading: isLoadingEdit }] = agreementsApi.useEditAgreementMutation()

  useEffect(() => {
    if (info) {
      triggerOwnerRepresentative({ companyId: info.owner_id })
      triggerCustomerRepresentative({ companyId: info.customer_id })
    }
  }, [info, triggerOwnerRepresentative, triggerCustomerRepresentative])

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        owner_id: Yup.string().required(t('form.errors.required') ?? ''),
        owner_representative_id: Yup.string().required(t('form.errors.required') ?? ''),
        customer_id: Yup.string().required(t('form.errors.required') ?? ''),
        customer_representative_id: Yup.string().required(t('form.errors.required') ?? ''),
        date: Yup.string().required(t('form.errors.required') ?? ''),
        agreement_number: Yup.string().required(t('form.errors.required') ?? ''),
      }),
    [t],
  )

  const initialValues = useMemo(
    () => ({
      owner_id: info?.owner_id ?? '',
      owner_representative_id: info?.owner_representative_id ?? '',
      customer_id: info?.customer_id ?? '',
      customer_representative_id: info?.customer_representative_id ?? '',
      date: info?.date ? moment(info.date) : moment(),
      agreement_number: info?.agreement_number ?? '',
    }),
    [info],
  )

  const successCallback = useCallback(() => {
    if (successCallbackModal) {
      successCallbackModal()
    } else {
      history.goBack()
    }
  }, [history, successCallbackModal])

  const onSubmit = useCallback(
    async (values: any) => {
      let result

      const body = {
        ...values,
        date: moment(values.date).format('YYYY-MM-DD'),
      }

      if (params.id && !isModal) {
        result = await edit({
          id: +params.id,
          body,
        })
      } else {
        result = await create(body)
      }

      if ('data' in result) {
        successCallback()
      }
    },
    [create, edit, isModal, successCallback, params.id],
  )

  const onCloseCreate = useCallback(() => {
    setIsCreate(false)
    setCreateType(null)
  }, [])

  const onCloseInfo = useCallback(() => {
    setIsShowInfo(false)
  }, [])

  const onOwnerChange = useCallback((value: any, setFieldValue: any) => {
    triggerOwnerRepresentative({ companyId: +value })
    setFieldValue('owner_representative_id', '')
  }, [triggerOwnerRepresentative])

  const onCustomerChange = useCallback((value: any, setFieldValue: any) => {
    triggerCustomerRepresentative({ companyId: +value })
    setFieldValue('customer_representative_id', '')
  }, [triggerCustomerRepresentative])

  return (
    <div>
      {!isModal && (
        <h1 className="page_title">
          {params.id ? t('agreementsCreate.titleEdit') : t('agreementsCreate.title')}
        </h1>
      )}
      <Preloader loading={isLoading}>
        <>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            innerRef={formRef}
            enableReinitialize
          >
            {({ values, setFieldValue }) => (
              <Row gutter={[24, 24]}>
                <Col xl={24} xxl={12}>
                  <Form>
                    <Row gutter={[20, 0]}>
                      <Col span={24}>
                        <h2>{t('agreementsCreate.ownerInfoBlock')}</h2>
                      </Col>
                      <Col xs={24} md={12}>
                        <div style={{ display: 'flex', gap: '10px' }}>
                          <SelectFormik
                            name="owner_id"
                            showSearch
                            placeholder={t('agreementsCreate.owner') ?? ''}
                            options={ownersSelect?.map((item) => ({ label: item.name, value: item.id })) || []}
                            onChange={(value) => onOwnerChange(value, setFieldValue)}
                          />
                          <Button
                            size="large"
                            onClick={() => {
                              setIsCreate(true)
                              setCreateType('owner')
                            }}
                          >
                            +
                          </Button>
                        </div>
                      </Col>
                      <Col xs={24} md={12}>
                        <SelectFormik
                          name="owner_representative_id"
                          options={ownerRepresentative?.map((item) => ({
                            value: item.id,
                            label: `${item.position}, ${item.name}`,
                          })) || []}
                          disabled={!values.owner_id}
                          placeholder={t('agreementsCreate.ownerRepresentative')!}
                        />
                      </Col>

                      <Col span={24}>
                        <h2>{t('agreementsCreate.customerInfoBlock')}</h2>
                      </Col>
                      <Col xs={24} md={12}>
                        <div style={{ display: 'flex', gap: '10px' }}>
                          <SelectFormik
                            name="customer_id"
                            showSearch
                            placeholder={t('agreementsCreate.customer') ?? ''}
                            options={customersSelect?.map((item) => ({ label: item.name, value: item.id })) || []}
                            onChange={(value) => onCustomerChange(value, setFieldValue)}
                          />
                          <Button
                            size="large"
                            onClick={() => {
                              setIsCreate(true)
                              setCreateType('customer')
                            }}
                          >
                            +
                          </Button>
                          {values?.customer_id && (
                            <Button
                              icon={<EyeOutlined />}
                              size="large"
                              style={{ flexShrink: 0 }}
                              onClick={() => {
                                setIsShowInfo(true)
                              }}
                            />
                          )}
                        </div>
                      </Col>
                      <Col xs={24} md={12}>
                        <SelectFormik
                          name="customer_representative_id"
                          options={customerRepresentative?.map((item) => ({
                            value: item.id,
                            label: `${item.position}, ${item.name}`,
                          })) || []}
                          disabled={!values.customer_id}
                          placeholder={t('agreementsCreate.customerRepresentative')!}
                        />
                      </Col>
                      <Col xs={24} md={12}>
                        <DatePickerFormik name="date" placeholder={t('agreementsCreate.date')} />
                      </Col>
                      <Col xs={24} md={12}>
                        <FieldFormik name="agreement_number" placeholder={t('agreements.columns.number')} />
                      </Col>
                    </Row>

                    <Button
                      htmlType="submit"
                      type="primary"
                      loading={isLoadingCreate || isLoadingEdit}
                    >
                      {params.id ? t('agreementsCreate.save') : t('agreementsCreate.submit')}
                    </Button>
                  </Form>
                </Col>
                {!isModal && (
                  <Col xl={24} xxl={12}>
                    <AgreementsCreateCard agreementId={params.id ? +params.id : undefined} />
                  </Col>
                )}
              </Row>
            )}
          </Formik>
          {/* {!!info?.invoices.length && (
            <Table<AgreementInvoicesType>
              dataSource={info.invoices}
              columns={columnsInvoices}
              className={style.table}
              scroll={{ x: 'max-content' }}
              pagination={false}
            />
          )} */}
        </>
      </Preloader>
      <Drawer
        title={
          (createType === 'customer' && t('customerCreate.title'))
          || (createType === 'owner' && t('ownerCreate.title'))
        }
        open={isCreate}
        onClose={onCloseCreate}
        width={1000}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {createType === 'customer' && <CustomerCreate isModal successCallbackModal={onCloseCreate} />}
        {createType === 'owner' && <OwnerCreate isModal successCallbackModal={onCloseCreate} />}
      </Drawer>
      <CustomerInfo isOpen={isShowInfo} onClose={onCloseInfo} customerId={formRef.current?.values.customer_id} />
    </div>
  )
}
