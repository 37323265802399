import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ColumnProps } from 'antd/lib/table'
import { PageTable } from '@src/components/Table/PageTable'
import { serviceTemplatesApi } from '@src/store/services/serviceTemplates-service'
import useQuery from '@src/hooks/useQuery'
import { ServiceTemplateType } from '@src/types/serviceTemplates'
import style from '@src/Pages/Cars/cars.module.scss'
import { Popconfirm } from 'antd'
import cn from 'classnames'
import { DeleteOutlined } from '@ant-design/icons'
import { addQuery } from '@src/lib/route'
import { isSelectionText } from '@src/lib/utils'

export const ServiceTemplates = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const pageQuery = useQuery('page')
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
  })
  const { data, isLoading } = serviceTemplatesApi.useGetServiceTemplatesListQuery(paramsFilters)
  const [deleteTemplate, { isLoading: isLoadingDeleteTemplate }] = serviceTemplatesApi.useDeleteServiceTemplateMutation()

  useEffect(() => {
    history.push({
      search: addQuery({
        ...paramsFilters,
      }),
    })
  }, [paramsFilters, history])

  const onCell = useCallback((record: ServiceTemplateType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/service-templates/edit/${record.id}`)
      }
    },
  }), [history])

  const columns = useMemo<ColumnProps<ServiceTemplateType>[]>(() => [
    {
      title: t('serviceTemplates.columns.index'),
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => ((index + 1) + ((paramsFilters.page - 1) * (data?.meta.pageSize || 0))),
      onCell,
    },
    {
      title: t('serviceTemplates.columns.name'),
      dataIndex: 'name',
      key: 'name',
      onCell,
    },
    {
      title: t('serviceTemplates.columns.description'),
      dataIndex: 'description',
      key: 'description',
      onCell,
    },
    {
      title: t('serviceTemplates.columns.author'),
      dataIndex: 'author',
      key: 'author',
      render: (author) => `${author.name} ${author.surname}`,
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      render: (_, record) => (
        <div className={style.actions}>
          <Popconfirm
            placement="left"
            title={t('popconfirm.deleteTitle')}
            onConfirm={() => { deleteTemplate(record.id) }}
            okText={t('popconfirm.okText')}
            cancelText={t('popconfirm.cancelText')}
            disabled={isLoadingDeleteTemplate}
          >
            <button
              type="button"
              className={cn(style.actions_item, style.actions_item__delete)}
              disabled={isLoadingDeleteTemplate}
            >
              <DeleteOutlined />
            </button>
          </Popconfirm>
        </div>
      ),
    },
  ], [data?.meta.pageSize, deleteTemplate, isLoadingDeleteTemplate, onCell, paramsFilters.page, t])

  return (
    <PageTable
      title={t('serviceTemplates.title')!}
      createLink="/service-templates/create"
      // filtersList={filtersList}
      setParamsFilters={setParamsFilters}
      paramsFilters={paramsFilters}
      tableData={{
        ...data,
        isLoading,
        columns,
      }}
    />
  )
}
