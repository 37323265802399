import { PageTable } from '@src/components/Table/PageTable'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useQuery from '@src/hooks/useQuery'
import { detailsApi } from '@src/store/services/details-service'
import { ColumnProps } from 'antd/lib/table'
import { NavLink, useHistory } from 'react-router-dom'
import { DetailType } from '@src/types/details'
import style from '@src/Pages/Cars/cars.module.scss'
import { Button, Popconfirm } from 'antd'
import cn from 'classnames'
import { CameraOutlined, DeleteOutlined } from '@ant-design/icons'
import { TableFiltersType } from '@src/types/tableFilters'
import { formatPrice } from '@src/lib/formatPrice'
import { addQuery } from '@src/lib/route'
import { isSelectionText } from '@src/lib/utils'

export const Details = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const pageQuery = useQuery('page')
  const filtersQuery = useQuery('filters')
  const sortQuery = useQuery('sort')
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
    filters: filtersQuery ? JSON.parse(filtersQuery) : {},
    sort: sortQuery ? JSON.parse(sortQuery) : [],
  })
  const { data, isLoading } = detailsApi.useGetDetailsListQuery(paramsFilters)
  const [deleteDetail, { isLoading: isLoadingDeleteDetail }] = detailsApi.useDeleteDetailMutation()
  const [exportDetails, { isLoading: isLoadingExport }] = detailsApi.useExportDetailsMutation()

  useEffect(() => {
    history.push({
      search: addQuery({ ...paramsFilters, filters: JSON.stringify(paramsFilters.filters), sort: JSON.stringify(paramsFilters.sort) }),
    })
  }, [paramsFilters, history])

  const onCell = useCallback((record: DetailType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/details/view/${record.id}`)
      }
    },
  }), [history])

  const columns = useMemo<ColumnProps<DetailType>[]>(() => [
    {
      title: t('details.columns.name'),
      dataIndex: 'name',
      key: 'name',
      onCell,
    },
    {
      title: t('details.columns.serialNumber'),
      dataIndex: 'serial_number',
      key: 'serial_number',
      onCell,
    },
    {
      title: t('details.columns.count'),
      dataIndex: 'count',
      key: 'count',
      sorter: true,
      onCell,
    },
    {
      title: t('details.columns.in_mechanic'),
      dataIndex: 'storage_count',
      key: 'storage_count',
      sorter: true,
      onCell,
    },
    {
      title: t('details.columns.total_count'),
      dataIndex: 'total_count',
      key: 'total_count',
      sorter: true,
      onCell,
    },
    {
      title: t('details.columns.unit'),
      dataIndex: 'unit',
      key: 'unit',
      onCell,
    },
    {
      title: t('details.columns.price'),
      dataIndex: 'price',
      key: 'price',
      render: (price) => formatPrice(price),
      sorter: true,
      onCell,
    },
    {
      title: t('details.columns.total_price'),
      dataIndex: 'total_price',
      key: 'total_price',
      render: (_, record) => (record.total_count >= 0 ? formatPrice(record.price * record.total_count) : t('details.columns.no_price')),
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      render: (_, record) => (
        <div className={style.actions}>
          <Popconfirm
            placement="left"
            title={t('popconfirm.deleteTitle')}
            onConfirm={() => { deleteDetail(record.id) }}
            okText={t('popconfirm.okText')}
            cancelText={t('popconfirm.cancelText')}
            disabled={isLoadingDeleteDetail}
          >
            <button
              type="button"
              className={cn(style.actions_item, style.actions_item__delete)}
              disabled={isLoadingDeleteDetail}
            >
              <DeleteOutlined />
            </button>
          </Popconfirm>
        </div>
      ),
    },
  ], [deleteDetail, isLoadingDeleteDetail, onCell, t])

  const filtersList = useMemo<TableFiltersType>(() => [
    {
      type: 'input',
      dataIndex: 'name',
      placeholder: t('details.columns.name'),
      width: '300px',
      operator: '$contains',
    },
    {
      type: 'input',
      dataIndex: 'serialNumbers.serial_number',
      placeholder: t('details.columns.serialNumber'),
      width: '300px',
      operator: '$contains',
    },
  ], [t])

  return (
    <PageTable
      title={t('details.title')!}
      createLink="/details/create"
      filtersList={filtersList}
      filtersV2
      setParamsFilters={setParamsFilters}
      paramsFilters={paramsFilters}
      onExport={exportDetails}
      isLoadingExport={isLoadingExport}
      actions={[
        <NavLink to="/mechanic/get-detail" key="scanner">
          <Button
            type="primary"
            icon={<CameraOutlined />}
            size="large"
          >
            {t('details.scanner')}
          </Button>
        </NavLink>,
      ]}
      tableData={{
        ...data,
        isLoading,
        columns,
      }}
    />
  )
}
