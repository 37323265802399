import { detailsApi } from '@src/store/services/details-service'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { serialize } from 'object-to-formdata'
import { Preloader } from '@src/components/Preloader/Preloader'
import { Form, Formik } from 'formik'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { Button, Col, Row } from 'antd'
import { usersApi } from '@src/store/services/users-service'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import style from './detailsAddModal.module.scss'

type Props = {
  detailId: number
  setIsTransferModalOpen: Dispatch<SetStateAction<boolean>>
  stock: number
}

export const DetailsTransferModal = ({ detailId, setIsTransferModalOpen, stock } : Props) => {
  const { t } = useTranslation()
  const [transferDetail, { isLoading: isTranferLoading }] = detailsApi.useTransferDetailMutation()
  const { data: workers, isLoading: isWorkersLoading } = usersApi.useGetWorkersForSelectQuery(
    undefined,
  )

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      to_id: Yup.number().required(t('form.errors.required') ?? ''),
      count: Yup.number().min(1, ({ min }) => `${t('form.errors.min')} ${min}`).max(stock, ({ max }) => `${t('form.errors.max')} ${max}`).required(t('form.errors.required') ?? ''),
    })
  ), [stock, t])

  const initialValues = useMemo(() => ({
    to_id: '',
    count: 1,
  }), [])

  const onSubmit = async (values: any) => {
    const body = {
      to_id: values.to_id,
      detail_id: detailId,
      count: values.count,
    }

    const formData = serialize(body, { indices: true })

    const result = await transferDetail(formData)

    if ('data' in result) {
      setIsTransferModalOpen(false)
    }
  }

  return (
    <div>
      <Preloader loading={isTranferLoading || isWorkersLoading}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {() => (
            <Form className={style.formWrapper}>
              <Row gutter={[20, 0]}>
                <Col xs={24} md={12}>
                  <FieldFormik
                    styleInline={{ width: '100%' }}
                    name="count"
                    placeholder={t('details.columns.quantity') ?? ''}
                    type="number"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <SelectFormik
                    name="to_id"
                    options={workers?.map((item) => ({ value: item.id, label: item.name })) || []}
                    placeholder={t('details.columns.mechanic') ?? ''}
                  />
                </Col>
              </Row>

              <div className={style.buttonWrapper}>
                <Button
                  htmlType="button"
                  type="default"
                  onClick={() => setIsTransferModalOpen(false)}
                  loading={isTranferLoading || isWorkersLoading}
                >
                  {t('details.close')}
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={isTranferLoading || isWorkersLoading}
                >
                  {t('details.transfer')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Preloader>
    </div>
  )
}
