import { TaskType } from '@src/types/tasks'
import { Card, Divider, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { FC, useMemo } from 'react'
import { TaskInformation } from '@src/components/pages/tasks/TaskInformation'
import { TaskRouteHistory } from '@src/components/pages/tasks/TaskRouteHistory'
import { TaskPhotoGallery } from '@src/components/pages/tasks/TaskPhotoGallery'
import { TaskTimelogs } from '@src/components/pages/tasks/TaskTimelogs'
import { TaskEndWorkQuizView } from '@src/components/pages/tasks/TaskEndWork/TaskEndWorkQuizView'
import { TaskComments } from '@src/components/pages/tasks/TaskComments'
import { ColumnProps } from 'antd/lib/table'

type TaskSignedViewProps = {
  data: TaskType
}

export const TaskSignedView: FC<TaskSignedViewProps> = ({ data }) => {
  const { t } = useTranslation()

  const detailsColumns = useMemo<ColumnProps<any>[]>(() => [
    {
      title: t('details.columns.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('details.columns.serialNumber'),
      dataIndex: 'serial_number',
      key: 'serial_number',
    },
    {
      title: t('details.columns.actualQuantityForTask'),
      dataIndex: 'spent_quantity',
      key: 'spent_quantity',
    },
    {
      title: t('details.columns.unitPrice'),
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: t('details.columns.price'),
      dataIndex: 'price',
      key: 'priceSum',
      render: (price, record) => price * record.spent_quantity,
    },
  ], [t])

  return (
    <Card title={data?.name}>
      <TaskInformation data={data!} isFinished />
      <Divider orientation="left" orientationMargin="0">
        {t('tasks.viewTabs.routeHistory')}
      </Divider>
      <TaskRouteHistory taskId={data.id} />
      <Divider orientation="left" orientationMargin="0">
        {t('tasks.viewTabs.photoGallery')}
      </Divider>
      <TaskPhotoGallery taskId={data.id} images={data?.images!} readonly />
      <Divider orientation="left" orientationMargin="0">
        {t('tasks.viewTabs.timelogs')}
      </Divider>
      <TaskTimelogs taskId={data.id} workers={data?.workers || []} />
      <Divider orientation="left" orientationMargin="0">
        {t('tasks.viewTabs.checkingAct')}
      </Divider>
      <TaskEndWorkQuizView quiz={data.quiz} />
      <br />
      <Table
        dataSource={data.details || []}
        columns={detailsColumns}
        pagination={false}
      />
      <Divider orientation="left" orientationMargin="0">
        {t('tasks.viewTabs.comments')}
      </Divider>
      <TaskComments taskId={data.id} comments={data?.comments!} readonly />
    </Card>
  )
}
