export enum BillsTypeEnum {
  AgreementInvoices = '1',
  TransportationBill = '2',
  BusinessInvoices = '3'
}

type SumType = {
  agreement_invoices: number
  business_invoices: number
  transportation_bills: number
}

export type BillsResponse = {
  data: any[]
//  agreement_bills: AgreementBillType[]
 /// agreement_invoices: AgreementInvoicesType[]
  // transportation_bills: TransportationBillType[]
  // business_invoices: InvoicesType[]
  email: string
}

export type BillsRevenueResponse = {
  sum: SumType
}
