import { FC, useState, forwardRef, useImperativeHandle, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { tasksApi } from '@src/store/services/tasks-service'
import { TaskRouteCreate } from '@src/components/pages/tasks/TaskRouteCreate'
import { PageTable } from '@src/components/Table/PageTable'
import { ColumnProps } from 'antd/lib/table'
import { TaskRouteType } from '@src/types/tasks'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { isSelectionText } from '@src/lib/utils'

type TaskRouteHistoryProps = {
  taskId: number
  ref?: any
  isCreate?: boolean
}

export const TaskRouteHistory: FC<TaskRouteHistoryProps> = forwardRef(({ taskId, isCreate }, ref) => {
  const { t } = useTranslation()
  const [paramsFilters, setParamsFilters] = useState({
    page: 1,
    filters: {
      filters: [
        { columns: 'task_id', value: taskId },
      ],
    },
  })
  const { data, isLoading } = tasksApi.useGetTaskRoutesListQuery(paramsFilters)
  const [isOpen, setIsOpen] = useState(false)
  const [editId, setEditId] = useState<undefined | number>()

  const onCell = useCallback((record: TaskRouteType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        setEditId(record.id)
        setIsOpen(true)
      }
    },
  }), [])

  const columns = useMemo<ColumnProps<TaskRouteType>[]>(() => [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => ((index + 1) + ((paramsFilters.page - 1) * (data?.meta.pageSize || 0))),
      onCell,
    },
    {
      title: t('taskRoute.columns.addressFrom'),
      dataIndex: 'address_from',
      key: 'address_from',
      onCell,
    },
    {
      title: t('taskRoute.columns.addressTo'),
      dataIndex: 'address_to',
      key: 'address_to',
      onCell,
    },
    {
      title: t('taskRoute.columns.distance'),
      dataIndex: 'distance',
      key: 'distance',
      onCell,
    },
    {
      title: t('taskRoute.columns.price'),
      dataIndex: 'price',
      key: 'price',
      onCell,
    },
  ], [data?.meta.pageSize, onCell, paramsFilters.page, t])

  useImperativeHandle(ref, () => ({
    setIsOpenCreate: () => setIsOpen(true),
  }))

  const onClose = () => {
    setIsOpen(false)
    setEditId(undefined)
  }

  return (
    <div>

      <PageTable
        setParamsFilters={setParamsFilters}
        isTableOnly
        tableData={{
          ...data,
          isLoading,
          columns,
        }}
      />
      {isCreate && (
        <Button
          key="createRouteHistory"
          type="primary"
          icon={<PlusOutlined />}
          size="middle"
          onClick={() => {
            setIsOpen(true)
          }}
        >
          {t('pageTable.create')}
        </Button>
      )}
      <TaskRouteCreate isOpen={isOpen} onClose={onClose} editId={editId} taskId={taskId} />
    </div>
  )
})
