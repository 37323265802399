import { useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { Button, Col, List, Row } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

import { Preloader } from '@src/components/Preloader/Preloader'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { DatePickerFormik } from '@src/components/DatePickerFormik/DatePickerFormik'
import { companiesApi } from '@src/store/services/companies-service'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import { detailsApi } from '@src/store/services/details-service'
import { formatPrice } from '@src/lib/formatPrice'
import * as Yup from 'yup'
import moment from 'moment'
import style from './detailsOffers.module.scss'

export const DetailsOffersCreate = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams<{ id?: string }>()
  const { data: companiesSelect } = companiesApi.useGetSelectCompaniesQuery()
  const { data: details, isLoading: detailsGetLoading } = detailsApi.useGetDetailsListForSelectQuery()
  const [create, { isLoading: isLoadingCreate }] = detailsApi.useCreateDetailOffersMutation()
  const [update, { isLoading: isLoadingUpdate }] = detailsApi.useUpdateDetailOffersMutation()
  const { data, isLoading: isLoadingGetOne } = detailsApi.useGetOneDetailOfferQuery(+params.id!, { skip: !params.id })

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      number: Yup.string().required(t('form.errors.required')!),
      company_id: Yup.string().required(t('form.errors.required')!),
      date: Yup.string().required(t('form.errors.required')!),
      details: Yup.array().of(Yup.object().shape({
        id: Yup.string().required(t('form.errors.required')!),
        count: Yup.number().required(t('form.errors.required')!),
        price: Yup.number().required(t('form.errors.required')!),
      })),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    number: data?.number || '',
    company_id: data?.company_id || null,
    date: data ? moment(data.date) : '',
    detailSelectTmp: data ? data.details.map((item) => item.id) : [],
    details: data ? data.details.map((item: any) => ({
      ...item,
      id: item.id,
      count: item.pivot?.count || 1,
      price: item.pivot?.price || item.price,
      store_price: item.price,
    })) : [],
    percentage: 40,
  }), [data])

  const handleDetailChange = useCallback((selectedIds: any, percentage: number, setFieldValue: any, formDetails?: any) => {
    const validSelectedIds = selectedIds.filter((id: number) => id !== undefined)
    const selectedDetails = details?.filter((item) => validSelectedIds.includes(item.id))

    const newDetails = selectedDetails?.map((detail) => ({
      id: detail.id,
      name: detail.name,
      serial_number: detail.serial_number,
      store_price: detail.price,
      price: detail.price ? ((detail.price * (percentage + 100)) / 100).toFixed(2) : 0,
      count: formDetails?.find((item: any) => item.id === detail.id)?.count || 1,
    }))

    setFieldValue('details', newDetails || [])
  }, [details])

  const handleDeleteDetail = useCallback((itemId: number, values: any, setFieldValue: any) => {
    const newDetails = values.details.filter((detail: { id: number; }) => detail.id !== itemId)
    const newSelectDetails = values.detailSelectTmp.filter((id: number) => id !== itemId)

    setFieldValue('detailSelectTmp', newSelectDetails)
    setFieldValue('details', newDetails)
  }, [])

  const onSubmit = async (values: any) => {
    const body = {
      ...values,
      date: values.date.format('YYYY-MM-DD'),
      details: values.details.reduce((acc: any, item: any) => ({
        ...acc,
        [item.id]: { count: item.count, price: item.price! },
      }), {}),
    }

    let result
    if (params.id) {
      result = await update({
        ...body,
        id: params.id,
      })
    } else {
      result = await create(body)
    }

    if ('data' in result) {
      history.goBack()
    }
  }

  return (
    <Preloader loading={isLoadingGetOne}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnChange={false}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Row gutter={[20, 0]} style={{ marginBottom: '24px' }}>
              <Col span={24}>
                <SelectFormik
                  name="company_id"
                  showSearch
                  placeholder={t('detailsOffers.columns.company')!}
                  options={companiesSelect?.map((item) => ({ label: item.name, value: item.id })) || []}
                />
              </Col>
              <Col xs={24} md={12}>
                <FieldFormik name="number" placeholder={t('detailsOffers.columns.number')!} />
              </Col>
              <Col xs={24} md={12}>
                <DatePickerFormik name="date" placeholder={t('detailsOffers.columns.date')!} />
              </Col>
              {!detailsGetLoading && (
                <Col xs={24} md={24}>
                  <SelectFormik
                    name="detailSelectTmp"
                    showSearch
                    isMultiple
                    placeholder={t('detailsOffers.columns.detail')}
                    options={details?.map((item) => ({
                      label: item.name,
                      value: item.id,
                    })) || []}
                    onChange={(value) => handleDetailChange(value, values.percentage, setFieldValue, values.details)}
                  />
                </Col>
              )}
            </Row>
            {values?.details?.length ? (
              <List
                bordered
                className={style.details}
                dataSource={values.details}
                style={{ marginBottom: 24 }}
                header={(
                  <div className={style.detailsReader}>
                    <div>{t('details.columns.name')}</div>
                    <div>{t('details.columns.serialNumber')}</div>
                    <div>{t('details.columns.storePrice')}</div>
                    <div>{t('details.columns.sellPrice')}</div>
                    <div>{t('detailsOffers.columns.count')}</div>
                  </div>
                )}
                renderItem={(item: any, index) => (
                  <List.Item>
                    <div className={style.detailsRow}>
                      <div>{item?.name}</div>
                      <div>{item?.serial_number}</div>
                      <div>{item?.store_price && formatPrice(item?.store_price)}</div>
                      <div>
                        <FieldFormik
                          name={`details[${index}].price`}
                          placeholder={t('detailsOffers.columns.price')}
                          size="middle"
                          noMarginBottom
                          type="number"
                        />
                      </div>
                      <div>
                        <FieldFormik
                          name={`details[${index}].count`}
                          placeholder={t('detailsOffers.columns.count')}
                          size="middle"
                          noMarginBottom
                          type="number"
                        />
                      </div>
                      <div>
                        <button
                          onClick={() => handleDeleteDetail(item.id, values, setFieldValue)}
                          type="button"
                        >
                          <DeleteOutlined />
                        </button>
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            ) : null}
            {values.details.length && !params.id ? (
              <Col xs={24} md={24}>
                <FieldFormik
                  name="percentage"
                  placeholder={t('detailsOffers.increasePrice')}
                  type="number"
                  onChange={(e) => {
                    const percentage = parseFloat(e.target.value) || 0
                    setFieldValue('percentage', percentage).then(() => {})
                    handleDetailChange(values.detailSelectTmp, percentage, setFieldValue, values.details)
                  }}
                />
              </Col>
            ) : null}
            <Button
              htmlType="submit"
              type="primary"
              loading={isLoadingCreate || isLoadingUpdate}
            >
              {params.id ? t('detailsOffers.update') : t('detailsOffers.submit')}
            </Button>
          </Form>
        )}
      </Formik>
    </Preloader>
  )
}
