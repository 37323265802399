import { FC, useState } from 'react'
import style from '@src/components/pages/cars/carViewContent.module.scss'
import { Image } from '@src/components/ImagesPreview/Image'
import { UploadImages } from '@src/components/UploadImages/UploadImages'
import { Button } from 'antd'
import { tasksApi } from '@src/store/services/tasks-service'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@src/store/store'
import { TaskImageType } from '@src/types/tasks'

type TaskPhotoGalleryProps = {
  taskId: number
  images: TaskImageType[]
  readonly?: boolean
}

export const TaskPhotoGallery: FC<TaskPhotoGalleryProps> = ({ taskId, images, readonly }) => {
  const dispatch = useDispatch<AppDispatch>()
  const [fileList, setFileList] = useState<any>([])
  const [storeFile] = tasksApi.useStoreTaskImageMutation()
  const [deleteImage] = tasksApi.useDeleteTaskImageMutation()

  const handleUpload = async () => {
    await Promise.all(fileList.map((image: any) => {
      const formData = new FormData()
      formData.append('image', image.originFileObj)

      return storeFile({ formData, taskId })
    }))

    setFileList([])
    dispatch(tasksApi.util.invalidateTags(['Tasks']))
  }

  return (
    <div>
      <div className={style.gallery}>
        {images.map((item) => (
          <Image
            key={item.id}
            url={`${process.env.REACT_APP_API_URL}/${item.image}`}
            thumb={`${process.env.REACT_APP_API_URL}/${item.thumb}`}
            onImageDelete={() => { deleteImage(item.id) }}
          />
        ))}
        {!readonly && (
          <>
            <UploadImages
              fileList={fileList}
              setFileList={(image) => {
                setFileList(image)
              }}
            />
            <Button
              type="primary"
              size="large"
              disabled={!fileList.length}
              onClick={handleUpload}
            >
              upload
            </Button>
          </>
        )}
      </div>
    </div>
  )
}
