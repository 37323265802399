import { Agreement } from '@src/types/agreements'

export enum AgreementInvoicesStatus {
  notProcessed = 1,
  processed = 2,
  archived = 3,
}

export type AgreementInvoicesType = {
  id: number
  agreement: Agreement
  agreement_id: number
  created_at: string
  date_created: string
  date_end: string
  date_start: string
  bill_number: number
  is_credit: number
  pay_by: string
  price: number
  updated_at: string
  status: AgreementInvoicesStatus
  text: string
  email: string
  company_id: number
  order_id: number
  owner_id: number
  order: any
  type: string
  unit: string
  quantity: string
  unit_price: string
  vat_calculated: boolean
  unit_text: string
}
