import React, { FC, useCallback, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Modal, Upload } from 'antd'
import type { UploadFile } from 'antd/es/upload/interface'
import { useTranslation } from 'react-i18next'
import type { RcFile, UploadProps } from 'antd/es/upload'

type UploadImagesProps = {
  fileList: any[]
  setFileList?: (data: UploadFile[]) => void
  isUploadButton?: boolean
  onRemove?: (data: any) => void
  isPictureCard?: boolean
}

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

export const UploadImages: FC<UploadImagesProps> = ({
  fileList,
  setFileList,
  isUploadButton = true,
  onRemove,
  isPictureCard = true,
}) => {
  const { t } = useTranslation()
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')

  const handleCancel = () => setPreviewOpen(false)

  const handlePreview = async (file: UploadFile) => {
    if (isPictureCard) {
      if (!file.url && !file.preview) {
        // eslint-disable-next-line no-param-reassign
        file.preview = await getBase64(file.originFileObj as RcFile)
      }

      setPreviewImage(file.url || (file.preview as string))
      setPreviewOpen(true)
      setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
    } else {
      // @ts-ignore
      window.open(file.url, '_blank').focus()
    }
  }

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    if (setFileList) {
      setFileList(newFileList)
    }
  }

  const onGalleryFileRemove = useCallback(
    (data: any) => {
      const { confirm } = Modal
      return new Promise((resolve, reject) => {
        confirm({
          title: t('confirmDeleteFileTitle'),
          onOk: () => {
            resolve(true)
            if (onRemove) {
              onRemove(data)
            }
          },
          onCancel: () => {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(true)
          },
        })
      })
    },
    [onRemove, t],
  )

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{t('common.upload')}</div>
    </div>
  )

  return (
    <>
      <Upload
        beforeUpload={() => false}
        listType={isPictureCard ? 'picture-card' : undefined}
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        // @ts-ignore
        onRemove={onGalleryFileRemove}
        multiple
      >
        {isUploadButton && uploadButton}
      </Upload>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel} width="80%">
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  )
}
