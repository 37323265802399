import { FC, ReactNode, useMemo } from 'react'
import { Storage } from '@src/types/details'
import { DetailsHistory } from './DetailsHistory'
import { DetailsInMechanicsStorage } from './DetailsInMechanicsStorage'

type DetailsViewContentProps = {
  activeTabKey: string
  detailId: number
  storages: Storage[]
}

export const DetailsViewContent: FC<DetailsViewContentProps> = ({ activeTabKey, detailId, storages }) => {
  const contentListRender = useMemo<Record<string, ReactNode>>(() => ({
    detailsHistory: <DetailsHistory detailId={detailId} />,
    detailsStorage: <DetailsInMechanicsStorage storages={storages} />,
  }), [detailId, storages])

  return contentListRender[activeTabKey]
}
