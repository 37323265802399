import {
  FC, useCallback, useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { Button, Col, Row } from 'antd'
import { Preloader } from '@src/components/Preloader/Preloader'
import { companiesApi } from '@src/store/services/companies-service'
import { CompanyType } from '@src/types/company'
import style from './customerCreate.module.scss'

type CustomerCreateProps = {
  isModal?: boolean
  successCallbackModal?: () => void
}

export const CustomerCreate: FC<CustomerCreateProps> = ({ isModal, successCallbackModal }) => {
  const { t } = useTranslation()
  const params = useParams<{ id?: string }>()
  const history = useHistory()
  const { data, isLoading: isLoadingGetOne } = companiesApi.useGetOneCompanyQuery(params.id ? +params.id : 0, { skip: !params.id || isModal })
  const [createCustomer, { isLoading: isLoadingCreate }] = companiesApi.useCreateCompanyMutation()
  const [editCustomer, { isLoading: isLoadingEdit }] = companiesApi.useEditCompanyMutation()

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      name: Yup.string().required(t('form.errors.required') ?? ''),
      company_code: Yup.string().required(t('form.errors.required') ?? ''),
      vat_number: Yup.string().required(t('form.errors.required') ?? ''),
      address: Yup.string().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    name: data?.name ?? '',
    company_code: data?.company_code ?? '',
    vat_number: data?.vat_number ?? '',
    address: data?.address ?? '',
    type: CompanyType.Customer,
  }), [data])

  const successCallback = useCallback(() => {
    if (successCallbackModal) {
      successCallbackModal()
    } else {
      history.goBack()
    }
  }, [history, successCallbackModal])

  const onSubmit = useCallback(async (values: any) => {
    let result

    if (params.id && !isModal) {
      result = await editCustomer({ ...values, id: params.id })
    } else {
      result = await createCustomer(values)
    }

    if ('data' in result) {
      successCallback()
    }
  }, [createCustomer, editCustomer, isModal, params.id, successCallback])

  return (
    <div>
      {!isModal && (
        <h1 className="page_title">
          {params.id ? t('customerCreate.titleEdit') : t('customerCreate.title')}
        </h1>
      )}
      <Preloader loading={isLoadingGetOne}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {() => (
            <Form className={style.form}>
              <Row gutter={[20, 0]}>
                <Col xs={24} md={12}>
                  <FieldFormik name="name" placeholder={t('customerCreate.name') ?? ''} />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik name="company_code" placeholder={t('customerCreate.code') ?? ''} />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik name="vat_number" placeholder={t('customerCreate.vatNumber') ?? ''} />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik name="address" placeholder={t('customerCreate.address') ?? ''} />
                </Col>
              </Row>

              <Button htmlType="submit" type="primary" loading={isLoadingCreate || isLoadingEdit}>
                {params.id && !isModal ? t('customerCreate.save') : t('customerCreate.submit')}
              </Button>
            </Form>
          )}
        </Formik>
      </Preloader>
    </div>
  )
}
