import { FC, Fragment, useMemo } from 'react'
import { UserRoles } from '@src/types/users'
import { authApi } from '@src/store/services/auth-service'
import { tasksApi } from '@src/store/services/tasks-service'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { Button, Divider } from 'antd'
import { RadioFormik } from '@src/components/RadioFormik/RadioFormik'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { AllowedTo } from '@src/components/AllowedTo/AllowedTo'
import { quizData } from '@src/components/pages/tasks/TaskEndWork/quizData'
import * as Yup from 'yup'

type TaskEndWorkQuizFormProps = {
  taskId: number
}

export const TaskEndWorkQuizForm: FC<TaskEndWorkQuizFormProps> = ({ taskId }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { data: user } = authApi.useGetMeQuery()
  const [taskFinish, { isLoading: isLoadingFinish }] = tasksApi.useTaskFinishMutation()

  const validationSchema = useMemo(() => {
    const quizSchema: any = {}

    quizData.forEach((section) => {
      section.children.forEach((item) => {
        quizSchema[item.name] = Yup.object().shape({
          value: Yup.string().required(t('form.errors.required') ?? ''),
        })
      })
    })

    return Yup.object().shape({
      quiz: Yup.object().shape(quizSchema),
    })
  }, [t])

  const initialValues = useMemo(() => {
    const quiz: any = {}

    quizData.forEach((section) => {
      section.children.forEach((item) => {
        quiz[item.name] = {
          value: '',
          comment: '',
        }
      })
    })

    return { quiz }
  }, [])

  const onSubmit = async (values: any) => {
    const result = await taskFinish({
      taskId,
      body: {
        quiz: JSON.stringify(values.quiz),
      },
    })

    if ('data' in result) {
      if (user?.role === UserRoles.Mechanic) {
        history.push('/')
      } else {
        history.goBack()
      }
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      <Form>
        {quizData.map((group) => (
          <Fragment key={group.title}>
            <Divider orientation="left" orientationMargin="0">{group.title}</Divider>
            {group.children.map((item, index) => (
              <Fragment key={item.name}>
                <div>
                  <RadioFormik
                    name={`quiz.${item.name}.value`}
                    placeholder={item.placeholder}
                    options={item.options.map((option) => ({
                      label: option,
                      value: option,
                    }))}
                  />
                </div>
                <br />
                <FieldFormik
                  name={`quiz.${item.name}.comment`}
                  placeholder="Komentaras"
                />
                {index < group.children.length - 1 && <br />}
              </Fragment>
            ))}
          </Fragment>
        ))}
        <AllowedTo roles={[UserRoles.Mechanic, UserRoles.Admin, UserRoles.ServiceManager]}>
          <Button type="primary" size="large" htmlType="submit" loading={isLoadingFinish}>
            {t('tasks.endWork.save')}
          </Button>
        </AllowedTo>
      </Form>
    </Formik>
  )
}
