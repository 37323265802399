import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ColumnProps } from 'antd/lib/table'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'
import { Popconfirm } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { PageTable } from '@src/components/Table/PageTable'
import useQuery from '@src/hooks/useQuery'
import { tasksApi } from '@src/store/services/tasks-service'
import { TaskStatusEnum, TaskType } from '@src/types/tasks'
import moment from 'moment'
import { TableFiltersType } from '@src/types/tableFilters'
import { companiesApi } from '@src/store/services/companies-service'
import { carsApi } from '@src/store/services/cars-service'
import { standardWorkApi } from '@src/store/services/standard-work-service'
import { usersApi } from '@src/store/services/users-service'
import { isSelectionText } from '@src/lib/utils'
import { addQuery } from '@src/lib/route'

export const Tasks = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const pageQuery = useQuery('page')
  const filtersQuery = useQuery('filters')
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
    filters: filtersQuery ? JSON.parse(filtersQuery) : {
      status: {
        $eq: TaskStatusEnum.New,
      },
    },
  })
  const { data: clientsSelect } = companiesApi.useGetSelectCompaniesQuery()
  const { data: carsSelect } = carsApi.useGetCarsForSelectQuery({})
  const { data: workTypes } = standardWorkApi.useGetWorkTypesListForSelectQuery()
  const { data: usersSelect } = usersApi.useGetUsersForSelectQuery(4)
  const { data, isLoading } = tasksApi.useGetTasksListQuery(paramsFilters)
  const [deleteDetail, { isLoading: isLoadingDeleteDetail }] = tasksApi.useDeleteTaskMutation()
  const { data: workersSelect } = usersApi.useGetWorkersForSelectQuery()

  useEffect(() => {
    history.push({
      search: addQuery({ ...paramsFilters, filters: JSON.stringify(paramsFilters.filters) }),
    })
  }, [paramsFilters, history])

  const onCell = useCallback((record: TaskType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/tasks/view/${record.id}`)
      }
    },
  }), [history])

  const columns = useMemo<ColumnProps<TaskType>[]>(() => [
    {
      title: t('tasks.columns.index'),
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => ((index + 1) + ((paramsFilters.page - 1) * (data?.meta.pageSize || 0))),
      onCell,
    },
    {
      title: t('tasks.columns.workType'),
      dataIndex: 'work_type',
      key: 'work_type',
      render: (workType) => workType.name,
      width: '200px',
      onCell,
    },
    {
      title: t('tasks.columns.name'),
      dataIndex: 'name',
      key: 'name',
      width: '200px',
      onCell,
    },
    {
      title: t('tasks.columns.client'),
      dataIndex: 'client',
      key: 'client',
      render: (client) => client?.name,
      onCell,
    },
    {
      title: t('tasks.columns.car'),
      dataIndex: 'car',
      key: 'car',
      render: (car) => `${car.make}/${car.model}(${car.serial_number})`,
      onCell,
    },
    {
      title: t('tasks.columns.plannedDateStart'),
      dataIndex: 'planned_date_start',
      key: 'planned_date_start',
      render: (plannedDateStart) => moment(plannedDateStart).format('YYYY-MM-DD HH:mm'),
      onCell,
    },
    {
      title: t('tasks.columns.plannedDateEnd'),
      dataIndex: 'planned_date_end',
      key: 'planned_date_end',
      render: (plannedDateEnd) => moment(plannedDateEnd).format('YYYY-MM-DD HH:mm'),
      onCell,
    },
    {
      title: t('tasks.columns.author'),
      dataIndex: 'author',
      key: 'author',
      render: (author) => `${author.name} ${author.surname}`,
      onCell,
    },
    {
      title: t('tasks.columns.workers'),
      dataIndex: 'workers',
      key: 'workers',
      // eslint-disable-next-line react/no-danger
      render: (workers) => <div dangerouslySetInnerHTML={{ __html: workers.map((item: any) => `${item.name} ${item.surname}`).join('<br/>') }} />,
      onCell,
    },
    {
      title: t('tasks.columns.description'),
      dataIndex: 'description',
      key: 'description',
      width: '200px',
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      render: (_, record) => (
        <div className="table_actions">
          {record.status === TaskStatusEnum.New && (
            <Popconfirm
              placement="left"
              title={t('popconfirm.deleteTitle')}
              onConfirm={() => {
                deleteDetail(record.id)
              }}
              okText={t('popconfirm.okText')}
              cancelText={t('popconfirm.cancelText')}
              disabled={isLoadingDeleteDetail}
            >
              <button
                type="button"
                className={cn('table_actions_item', 'table_actions_item__delete')}
                disabled={isLoadingDeleteDetail}
              >
                <DeleteOutlined />
              </button>
            </Popconfirm>
          )}
        </div>
      ),
    },
  ], [t, onCell, paramsFilters.page, data?.meta.pageSize, isLoadingDeleteDetail, deleteDetail])

  const filtersList = useMemo<TableFiltersType>(() => [
    {
      type: 'input',
      dataIndex: 'name',
      placeholder: t('tasks.columns.name'),
      width: '300px',
      operator: '$contains',
    },
    {
      type: 'select',
      dataIndex: 'client_id',
      placeholder: t('tasks.columns.client'),
      options: clientsSelect?.map((item) => ({ value: item.id, label: item.name })) || [],
      width: '200px',
      operator: '$eq',
    },
    {
      type: 'select',
      dataIndex: 'car_id',
      placeholder: t('tasks.columns.car'),
      options: carsSelect?.map((item) => ({ value: item.id, label: item.name })) || [],
      width: '350px',
      operator: '$eq',
    },
    {
      type: 'select',
      dataIndex: 'work_type_id',
      placeholder: t('tasks.columns.workType'),
      options: workTypes?.map((item) => ({ value: item.id, label: item.name })) || [],
      width: '350px',
      operator: '$eq',
    },
    {
      type: 'select',
      dataIndex: 'author_id',
      placeholder: t('tasks.columns.author'),
      options: usersSelect?.map((item) => ({ value: item.id, label: item.name })) || [],
      width: '200px',
      operator: '$eq',
    },
    {
      type: 'select',
      dataIndex: 'workers.users|.id',
      placeholder: t('tasks.columns.workers'),
      options: workersSelect?.map((item) => ({ value: item.id, label: item.name })) || [],
      width: '200px',
      operator: '$eq',
    },
  ], [usersSelect, workTypes, carsSelect, clientsSelect, t, workersSelect])

  const onTabChange = (key: string) => {
    setParamsFilters((prevState) => ({
      ...prevState,
      page: 1,
      filters: {
        ...prevState.filters,
        status: {
          $eq: key as TaskStatusEnum,
        },
      },
    }))
  }

  return (
    <PageTable
      title={t('tasks.title')!}
      createLink="/tasks/create"
      tabList={Object.values(TaskStatusEnum).map((item) => ({
        key: item,
        label: t(`tasks.statuses.${item}`)!,
      }))}
      activeTabKey={paramsFilters.filters.status.$eq}
      onTabChange={onTabChange}
      filtersList={filtersList}
      filtersV2
      setParamsFilters={setParamsFilters}
      paramsFilters={paramsFilters}
      tableData={{
        ...data,
        isLoading,
        columns,
      }}
    />
  )
}
