import React, { ReactNode, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card } from 'antd'
import { AgreementOrderDocument } from '@src/components/pages/agreements/AgreementOrderDocument'

export const AgreementOrderCreateCard = () => {
  const { t } = useTranslation()
  const [activeTabKey, setActiveTabKey] = useState<string>('document')

  const contentList: Record<string, ReactNode> = useMemo(() => ({
    document: (
      <AgreementOrderDocument />
    ),
  }), [])

  return (
    <Card
      activeTabKey={activeTabKey}
      onTabChange={setActiveTabKey}
      tabList={[
        {
          key: 'document',
          label: t('agreementsCreate.card.document'),
        },
      ]}
      tabProps={{
        size: 'middle',
      }}
    >
      {contentList[activeTabKey]}
    </Card>
  )
}
