import { PageTable } from '@src/components/Table/PageTable'
import useQuery from '@src/hooks/useQuery'
import { Storage } from '@src/types/details'
import { ColumnProps } from 'antd/es/table'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  storages: Storage[]
}

export const DetailsInMechanicsStorage = ({ storages }: Props) => {
  const { t } = useTranslation()
  const pageQuery = useQuery('page')
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
  })

  const columns = useMemo<ColumnProps<Storage>[]>(() => [
    {
      title: t('details.columns.mechanic'),
      dataIndex: 'mechanic',
      key: 'mechanic',
      render: (_, record) => {
        console.log(record)
        return `${record.user.name} ${record.user.surname}`
      },
      width: '200px',
    },
    {
      title: t('details.columns.count'),
      dataIndex: 'count',
      key: 'count',
      render: (_, record) => record.count,
      width: '200px',
    },
  ], [t])

  return (
    <PageTable
      setParamsFilters={setParamsFilters}
      paramsFilters={paramsFilters}
      tableData={{
        data: storages,
        isLoading: false,
        columns,
      }}
      isTableOnly
    />
  )
}
