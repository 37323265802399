import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Col, Collapse, Drawer, Row, Table, Tag } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { UploadFile } from 'antd/es/upload/interface'
import { FileTextOutlined } from '@ant-design/icons'
import { Form, Formik } from 'formik'
import { Preloader } from '@src/components/Preloader/Preloader'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import { AgreementsCreate } from '@src/Pages/Agreements/AgreementsCreate'
import { CarsCreate } from '@src/Pages/CarsCreate/CarsCreate'
import { RadioFormik } from '@src/components/RadioFormik/RadioFormik'
import { DatePickerFormik } from '@src/components/DatePickerFormik/DatePickerFormik'
import { TimePickerFormik } from '@src/components/TimePickerFormik/TimePickerFormik'
import { UploadImages } from '@src/components/UploadImages/UploadImages'
import ImagesPreview, { Image } from '@src/components/ImagesPreview/ImagesPreview'
import { AgreementInvoicesStatus, AgreementInvoicesType } from '@src/types/agreementInvoices'
import { agreementsApi } from '@src/store/services/agreements-service'
import { carsApi } from '@src/store/services/cars-service'
import { isSelectionText } from '@src/lib/utils'
import { customerWorkersApi } from '@src/store/services/customerWorkers-service'
import { AgreementOrderCreateCard } from '@src/components/pages/agreements/AgreementOrderCreateCard'
import moment from 'moment'
import * as Yup from 'yup'
import { CarRecord } from '@src/types/car'
import style from './agreementOrders.module.scss'

export const AgreementOrderCreate = () => {
  const formRef = useRef<any>()
  const { t } = useTranslation()
  const params = useParams<{ id?: string }>()
  const history = useHistory()
  const [isCreate, setIsCreate] = useState<boolean>(false)
  const [createType, setCreateType] = useState<'car' | 'agreement' | null>(null)
  const { data: info, isLoading } = agreementsApi.useGetOneAgreementOrderQuery(+params.id!, { skip: !params.id })
  const [create, { isLoading: isLoadingCreate }] = agreementsApi.useCreateAgreementOrderMutation()
  const [edit, { isLoading: isLoadingEdit }] = agreementsApi.useEditAgreementOrderMutation()
  const [triggerAgreement, { data: agreement }] = agreementsApi.useLazyGetOneAgreementQuery()
  const [triggerOwnerRepresentative, { data: ownerRepresentative }] = customerWorkersApi.useLazyListQuery()
  const [triggerCustomerRepresentative, { data: customerRepresentative }] = customerWorkersApi.useLazyListQuery()
  const { data: carsSelect } = carsApi.useGetCarsForSelectQuery({
    filters: !params?.id ? {
      status: {
        $eq: 0,
      },
    } : {},
  })
  const [deleteFileAgreements] = agreementsApi.useDeleteFileMutation()
  const [storeImage, { isLoading: isLoadingStoreImage }] = carsApi.useStoreFileMutation()
  const [storeFileAttachment, { isLoading: isLoadingStoreFileAttachment }] = agreementsApi.useStoreFileAttachmentMutation()
  const { data: agreementsSelect } = agreementsApi.useGetShortListAgreementsQuery()
  const [triggerCar, { data: car }] = carsApi.useLazyGetOneCarQuery()

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        insurance: Yup.string().required(t('form.errors.required') ?? ''),
        minimum_rent: Yup.number().required(t('form.errors.required') ?? ''),
        payment_deadline: Yup.string().required(t('form.errors.required') ?? ''),
        lease_term: Yup.string().required(t('form.errors.required') ?? ''),
        max_engine_hours: Yup.string().required(t('form.errors.required') ?? ''),
        engine_hour_cost: Yup.string().required(t('form.errors.required') ?? ''),
        rent_place: Yup.string().required(t('form.errors.required') ?? ''),
        date_start: Yup.string().required(t('form.errors.required') ?? ''),
        car_id: Yup.string().required(t('form.errors.required') ?? ''),
        engine_hours_start: Yup.string().required(t('form.errors.required') ?? ''),
        payment_term: Yup.string().required(t('form.errors.required') ?? ''),
        agreement_id: Yup.string().required(t('form.errors.required') ?? ''),
        owner_representative_id: Yup.string().required(t('form.errors.required') ?? ''),
        customer_representative_id: Yup.string().required(t('form.errors.required') ?? ''),
      }),
    [t],
  )

  const initialValues = useMemo(
    () => ({
      agreementOrderNumber: info?.agreement_order_number ?? '',
      agreement_id: info?.agreement_id ?? '',
      car_id: info?.car_id ?? '',
      engine_hours_end: info?.car_engine_log?.engine_hours_end ?? '',
      engine_hours_start: info?.car_engine_log?.engine_hours_start ?? '',
      rent_place: info?.rent_place ?? 'Savanorių pr. 247, Vilnius, 02300',
      date_start: info?.date_start ? moment(info?.date_start) : '',
      date_end: info?.date_end ? moment(info?.date_end) : '',
      time_rent: info?.time_rent ? moment(info?.time_rent, 'HH:mm') : moment().startOf('hour'),
      equipment: info?.equipment ? JSON.parse(info?.equipment) : ([] as any),
      services: info?.services ? JSON.parse(info?.services) : ([] as any),
      insurance: info?.insurance ?? 'Nuomotojas',
      minimum_rent: info?.minimum_rent ?? '',
      payment_deadline: info?.payment_deadline ?? '15 dienų',
      lease_term: info?.lease_term ?? '',
      max_engine_hours: info?.max_engine_hours ?? '',
      engine_hour_cost: info?.engine_hour_cost ?? '',
      status: info?.status ?? 0,
      is_tank_full: info?.is_tank_full ?? 0,
      comment: info?.comment ?? '',
      comment_close: info?.comment_close ?? '',
      fileList: [] as UploadFile[],
      fileListAttachment: [],
      images: info?.images || {},
      payment_term: info?.payment_term || '',
      term_type: 'tmp',
      owner_representative_id: info ? info.owner_representative_id : '',
      customer_representative_id: info ? info.customer_representative_id : '',
    }),
    [info],
  )

  useEffect(() => {
    if (info) {
      triggerCar(info.car_id)
    }
  }, [info, triggerCar])

  const onSubmit = useCallback(
    async (values: any) => {
      const data = {
        ...values,
        date_start: values.date_start.format('YYYY-MM-DD'),
        date_end: values.date_end ? values.date_end.format('YYYY-MM-DD') : '',
        time_rent: values.time_rent ? values.time_rent.format('HH:mm:ss') : '',
        equipment: JSON.stringify(values.equipment),
        services: JSON.stringify(values.services),
      }

      let result

      if (params.id) {
        result = await edit({
          id: +params.id,
          body: data,
        })
      } else {
        result = await create(data)
      }

      if ('data' in result) {
        if (values.fileList.length) {
          const filesPromise = data.fileList.map((item: any) => {
            const formData = new FormData()
            formData.append('image', item.originFileObj)

            return storeImage({
              body: formData,
              car_id: values.car_id,
              agreement_order_id: params.id ? +params.id : result!.data.id,
              status: values.status,
            })
          })
          await Promise.all(filesPromise)
        }

        if (data.fileListAttachment.length) {
          const filesPromise = data.fileListAttachment.map((item: any) => {
            const formData = new FormData()
            formData.append('attachment', item.originFileObj)

            return storeFileAttachment({
              body: formData,
              agreement_order_id: params.id ? +params.id : result!.data.id,
            })
          })
          await Promise.all(filesPromise)
        }

        history.goBack()
      }
    },
    [create, edit, history, params.id, storeFileAttachment, storeImage],
  )

  const onCarChange = useCallback((record: CarRecord, setFieldValue: any) => {
    triggerCar(record.value)
    setFieldValue('engine_hours_start', record.engineHours)
  }, [triggerCar])

  const onCloseCreate = useCallback(() => {
    setIsCreate(false)
    setCreateType(null)
  }, [])

  useEffect(() => {
    if (info) {
      triggerOwnerRepresentative({ companyId: +info.agreement.owner_id })
      triggerCustomerRepresentative({ companyId: +info.agreement.customer_id })
    }
  }, [info, triggerOwnerRepresentative, triggerCustomerRepresentative])

  useEffect(() => {
    if (agreement) {
      triggerOwnerRepresentative({ companyId: +agreement.owner_id })
      triggerCustomerRepresentative({ companyId: +agreement.customer_id })
      if (formRef.current) {
        formRef.current.setFieldValue('owner_representative_id', agreement.owner_representative_id)
        formRef.current.setFieldValue('customer_representative_id', agreement.customer_representative_id)
      }
    }
  }, [agreement, triggerOwnerRepresentative, triggerCustomerRepresentative])

  const onAgreementChange = useCallback((value: any, setFieldValue: any) => {
    triggerAgreement(+value)
    setFieldValue('owner_representative_id', '')
    setFieldValue('customer_representative_id', '')
  }, [triggerAgreement])

  const onCell = useCallback((record: AgreementInvoicesType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/agreement-invoices/edit/${record.id}`)
      }
    },
  }), [history])

  const columnsInvoices = useMemo<ColumnProps<AgreementInvoicesType>[]>(() => [
    {
      title: t('agreementInvoices.columns.index'),
      dataIndex: 'index',
      key: 'index',
      render: (i, record, index) => index + 1,
      onCell,
    },
    {
      title: t('agreementInvoices.columns.dateStart'),
      dataIndex: 'date_start',
      key: 'date_start',
      onCell,
    },
    {
      title: t('agreementInvoices.columns.dateEnd'),
      dataIndex: 'date_end',
      key: 'date_end',
      onCell,
    },
    {
      title: t('agreementInvoices.columns.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        switch (status) {
          case AgreementInvoicesStatus.notProcessed:
            return <Tag color="blue">{t(`agreementInvoices.status.${AgreementInvoicesStatus.notProcessed}`)}</Tag>
          case AgreementInvoicesStatus.processed:
            return <Tag color="green">{t(`agreementInvoices.status.${AgreementInvoicesStatus.processed}`)}</Tag>
          case AgreementInvoicesStatus.archived:
            return <Tag color="red">{t(`agreementInvoices.status.${AgreementInvoicesStatus.archived}`)}</Tag>
          default:
            return ''
        }
      },
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      fixed: 'right',
      render: (actions, record) => (
        <div className={style.actions}>
          <a
            // eslint-disable-next-line max-len
            href={`${process.env.REACT_APP_API_URL}/api/agreements/generate-invoice/${record.id}?access_token=${localStorage.getItem('token')}`}
            target="_blank"
            rel="noreferrer"
            className={style.actions_item}
          >
            <FileTextOutlined />
          </a>
        </div>
      ),
    },
  ], [onCell, t])

  return (
    <div>
      <h1 className="page_title">{params.id ? t('agreementsCreate.titleEdit') : t('agreementsCreate.title')}</h1>
      <Preloader loading={isLoading}>
        <>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            innerRef={formRef}
            enableReinitialize
          >
            {({ values, setFieldValue }) => (
              <Row gutter={[24, 24]}>
                <Col xl={24} xxl={12}>
                  <Form>
                    <Row gutter={[20, 0]}>
                      <Col span={24}>
                        <h2>{t('agreementsCreate.carInfoBlock')}</h2>
                      </Col>
                      <Col span={24}>
                        <div style={{ display: 'flex', gap: '10px' }}>
                          <SelectFormik
                            width="calc(100% - 40px)"
                            name="car_id"
                            showSearch
                            onChange={(_, current) => onCarChange(current, setFieldValue)}
                            placeholder={t('agreementsCreate.car') ?? ''}
                            options={carsSelect?.map((item) => ({
                              label: item.name, value: item.id, ownerId: item.owner_id, engineHours: item.engine_hours,
                            })) || []}
                          />
                          <Button
                            size="large"
                            onClick={() => {
                              setIsCreate(true)
                              setCreateType('car')
                            }}
                          >
                            +
                          </Button>
                        </div>
                        {car?.owner && (
                          <div style={{ marginBottom: 20 }}>
                            <Tag color="blue">{car.owner.name}</Tag>
                          </div>
                        )}
                      </Col>
                      <Col xs={24} md={12}>
                        <FieldFormik name="engine_hours_start" placeholder={t('agreementsCreate.engineHoursStart') ?? ''} />
                      </Col>
                      <Col xs={24} md={12}>
                        <FieldFormik name="engine_hours_end" placeholder={t('agreementsCreate.engineHoursEnd') ?? ''} />
                      </Col>
                      <Col span={24}>
                        <div style={{ display: 'flex', gap: '10px' }}>
                          <SelectFormik
                            name="agreement_id"
                            options={agreementsSelect?.map((item) => ({ value: item.id, label: item.name })) || []}
                            placeholder={t('agreements.title')}
                            disabled={!!params.id}
                            onChange={(value) => onAgreementChange(value, setFieldValue)}
                          />
                          <Button
                            size="large"
                            onClick={() => {
                              setIsCreate(true)
                              setCreateType('agreement')
                            }}
                          >
                            +
                          </Button>
                        </div>
                      </Col>
                      <Col xs={24} md={12}>
                        <SelectFormik
                          name="owner_representative_id"
                          options={ownerRepresentative?.map((item) => ({
                            value: item.id,
                            label: `${item.position}, ${item.name}`,
                          })) || []}
                          disabled={!values.agreement_id}
                          placeholder={t('agreementsCreate.ownerRepresentative')!}
                        />
                      </Col>
                      <Col xs={24} md={12}>
                        <SelectFormik
                          name="customer_representative_id"
                          options={customerRepresentative?.map((item) => ({
                            value: item.id,
                            label: `${item.position}, ${item.name}`,
                          })) || []}
                          disabled={!values.agreement_id}
                          placeholder={t('agreementsCreate.customerRepresentative')!}
                        />
                      </Col>
                      <Col span={24}>
                        <div style={{ marginBottom: 15 }}>
                          <span style={{ marginRight: 15 }}>{t('agreementsCreate.isTankFull')}</span>
                          <RadioFormik
                            options={[
                              { value: 1, label: t('popconfirm.okText') },
                              { value: 0, label: t('popconfirm.cancelText') },
                            ]}
                            name="is_tank_full"
                          />
                        </div>
                      </Col>
                      {values.equipment.map((item: any, i: any) => (
                      // eslint-disable-next-line react/no-array-index-key
                        <Fragment key={i}>
                          <Col xs={24} md={10}>
                            <FieldFormik
                              name={`equipment[${i}].name`}
                              placeholder={t('agreementsCreate.customEquipmentName') ?? ''}
                            />
                          </Col>
                          <Col xs={24} md={10}>
                            <FieldFormik
                              name={`equipment[${i}].value`}
                              placeholder={t('agreementsCreate.customEquipmentValue') ?? ''}
                            />
                          </Col>
                          <Col xs={24} md={4}>
                            <Button
                              size="large"
                              onClick={() => {
                                setFieldValue('equipment', values.equipment.filter((equipment: any, filterI: number) => filterI !== i))
                              }}
                            >
                              {t('common.delete')}
                            </Button>
                          </Col>
                        </Fragment>
                      ))}
                      <Col span={24}>
                        <Button
                          onClick={() => {
                            setFieldValue('equipment', [...values.equipment, { name: '', value: '' }])
                          }}
                        >
                          {t('agreementsCreate.addEquipment')}
                        </Button>
                      </Col>
                      <div style={{ display: 'flex', height: '24px', width: '100%' }} />
                      {values.services.map((item: any, i: any) => (
                      // eslint-disable-next-line react/no-array-index-key
                        <Fragment key={i}>
                          <Col xs={24} md={10}>
                            <FieldFormik
                              name={`services[${i}].name`}
                              placeholder={t('agreementsCreate.customEquipmentName') ?? ''}
                            />
                          </Col>
                          <Col xs={24} md={10}>
                            <FieldFormik
                              name={`services[${i}].value`}
                              placeholder={t('agreementsCreate.customEquipmentValue') ?? ''}
                            />
                          </Col>
                          <Col xs={24} md={4}>
                            <Button
                              size="large"
                              onClick={() => {
                                setFieldValue('services', values.services.filter((service: any, filterI: number) => filterI !== i))
                              }}
                            >
                              {t('common.delete')}
                            </Button>
                          </Col>
                        </Fragment>
                      ))}

                      <Col span={24}>
                        <Button
                          onClick={() => {
                            setFieldValue('services', [...values.services, { name: '', value: '' }])
                          }}
                        >
                          {t('agreementsCreate.addServices')}
                        </Button>
                      </Col>

                      <Col span={24}>
                        <h2>{t('agreementsCreate.agreementInfoBlock')}</h2>
                      </Col>
                      <Col xs={24} md={12}>
                        <SelectFormik
                          name="insurance"
                          placeholder={t('agreementsCreate.insurance') ?? ''}
                          options={[
                            {
                              value: 'Nuomotojas',
                              label: 'Nuomotojas',
                            },
                            {
                              value: 'Nuomininkas',
                              label: 'Nuomininkas',
                            },
                            {
                              value: 'Nera',
                              label: 'Nera',
                            },
                          ]}
                        />
                      </Col>
                      <Col xs={24} md={12}>
                        <FieldFormik
                          name="payment_deadline"
                          placeholder={t('agreementsCreate.paymentDeadline') ?? ''}
                          type="autoComplete"
                          autoCompleteOptions={[
                            '15 dienų',
                            '30 dienų',
                            '15 dienų nuo sąskaitos faktūros išrašymo dienos',
                            '30 dienų nuo sąskaitos faktūros išrašymo dienos',
                          ]}
                        />
                      </Col>
                      <Col xs={24} md={8}>
                        <FieldFormik
                          name="minimum_rent"
                          type="number"
                          placeholder={t('agreementsCreate.minimumRent') ?? ''}
                        />
                      </Col>

                      <Col xs={24} md={8}>
                        <FieldFormik
                          name="lease_term"
                          type="number"
                          placeholder={t('agreementsCreate.leaseTerm') ?? ''}
                        />
                      </Col>
                      <Col xs={24} md={8}>
                        <SelectFormik
                          name="payment_term"
                          options={[
                            {
                              value: 'months',
                              label: t('agreementsCreate.paymentTerms.months'),
                            },
                            {
                              value: 'days',
                              label: t('agreementsCreate.paymentTerms.days'),
                            },
                            {
                              value: 'hours',
                              label: t('agreementsCreate.paymentTerms.hours'),
                            },
                          ]}
                          placeholder={t('agreementsCreate.paymentTerm')}
                        />
                      </Col>
                      <Col xs={24} md={12}>
                        <FieldFormik
                          name="max_engine_hours"
                          type="number"
                          placeholder={t('agreementsCreate.maxEngineHours') ?? ''}
                        />
                      </Col>
                      <Col xs={24} md={12}>
                        <FieldFormik
                          name="engine_hour_cost"
                          type="number"
                          placeholder={t('agreementsCreate.engineHourCost') ?? ''}
                        />
                      </Col>
                      <Col xs={24} md={12}>
                        <DatePickerFormik name="date_start" placeholder={t('agreementsCreate.dateStart') ?? ''} />
                      </Col>
                      {params.id ? (
                        <Col xs={24} md={12}>
                          <DatePickerFormik name="date_end" placeholder={t('agreementsCreate.dateEnd') ?? ''} />
                        </Col>
                      ) : (
                        <Col xs={24} md={12} />
                      )}
                      <Col xs={24} md={12}>
                        <FieldFormik name="rent_place" placeholder={t('agreementsCreate.rentPlace') ?? ''} />
                      </Col>
                      <Col xs={24} md={12}>
                        <TimePickerFormik name="time_rent" placeholder={t('agreementsCreate.timeRent') ?? ''} />
                      </Col>
                      <Col xs={24} md={24}>
                        <FieldFormik
                          type="textarea"
                          name="comment"
                          autoSize={{ minRows: 4, maxRows: 4 }}
                          placeholder={t('transportationBills.columns.comment') ?? ''}
                        />
                      </Col>
                      <Col xs={24} md={24}>
                        <FieldFormik
                          type="textarea"
                          name="comment_close"
                          autoSize={{ minRows: 4, maxRows: 4 }}
                          placeholder={t('transportationBills.columns.commentClose') ?? ''}
                        />
                      </Col>
                      <Col xs={24} md={12}>
                        <div style={{ marginBottom: 15 }}>
                          <span style={{ marginRight: 15 }}>{t('agreementsCreate.status')}</span>
                          <RadioFormik
                            options={[
                              { value: 0, label: t('agreementsCreate.statusOpen') },
                              { value: 1, label: t('agreementsCreate.statusClosed') },
                            ]}
                            name="status"
                          />
                        </div>
                      </Col>
                    </Row>

                    <h3>{t('agreementsCreate.imagesTitle')}</h3>
                    <div className={style.upload}>
                      <UploadImages
                        fileList={values.fileList}
                        setFileList={(data) => {
                          setFieldValue('fileList', data)
                        }}
                      />
                    </div>

                    {info?.images && (
                      <Collapse accordion className={style.upload}>
                        {Object.keys(info.images)
                          .sort((a, b) => moment(a, 'YYYY-MM-DD').valueOf() - moment(b, 'YYYY-MM-DD').valueOf())
                          .map((key) => (
                            <Collapse.Panel header={key} key={key}>
                              <ImagesPreview
                                images={info.images[key]}
                                groupKey={key}
                                onReorderImages={(data: Image[]) => {
                                  setFieldValue('images', { ...info.images, [key]: data })
                                }}
                              />
                            </Collapse.Panel>
                          ))}
                      </Collapse>
                    )}

                    <h3>{t('agreementsCreate.filesTitle')}</h3>
                    <div className={style.upload}>
                      <UploadImages
                        fileList={values.fileListAttachment}
                        setFileList={(data) => {
                          setFieldValue('fileListAttachment', data)
                        }}
                      />
                    </div>
                    {info?.attachments && (
                      <Row>
                        <Col xs={24} md={12}>
                          <div className={style.upload}>
                            <UploadImages
                              isUploadButton={false}
                              fileList={info.attachments.map((el: any) => ({
                                url: `${process.env.REACT_APP_API_URL}/${el.file}`,
                                id: el.id,
                                name: el.filename,
                              }))}
                              onRemove={(data) => {
                                deleteFileAgreements(data.id)
                              }}
                              isPictureCard={false}
                            />
                          </div>
                        </Col>
                      </Row>
                    )}

                    <Button
                      htmlType="submit"
                      type="primary"
                      loading={isLoadingCreate || isLoadingEdit || isLoadingStoreImage || isLoadingStoreFileAttachment}
                    >
                      {params.id ? t('agreementsCreate.save') : t('agreementsCreate.submit')}
                    </Button>
                  </Form>
                </Col>
                <Col xl={24} xxl={12}>
                  <AgreementOrderCreateCard />
                </Col>
              </Row>
            )}
          </Formik>
          {!!info?.invoices?.length && (
            <Table<AgreementInvoicesType>
              dataSource={info.invoices}
              columns={columnsInvoices}
              className={style.table}
              scroll={{ x: 'max-content' }}
              pagination={false}
            />
          )}

        </>
      </Preloader>
      <Drawer
        title={
          (createType === 'car' && t('carCreate.title'))
          || (createType === 'agreement' && t('agreementsCreate.title'))
          || ''
        }
        open={isCreate}
        onClose={onCloseCreate}
        width={1000}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {createType === 'car' && <CarsCreate isModal successCallbackModal={onCloseCreate} />}
        {createType === 'agreement' && <AgreementsCreate isModal successCallbackModal={onCloseCreate} />}
      </Drawer>
    </div>
  )
}
