import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ColumnProps } from 'antd/lib/table'
import { Popconfirm } from 'antd'
import { CheckCircleOutlined, CheckOutlined } from '@ant-design/icons'
import moment from 'moment'
import cn from 'classnames'

import useQuery from '@src/hooks/useQuery'
import { PageTable } from '@src/components/Table/PageTable'
import { NotificationType } from '@src/types/notification'
import { notificationApi } from '@src/store/services/notification-service'
import { usePageFocus } from '@src/hooks/usePageFocus'
import { UserRoles } from '@src/types/users'
import { authApi } from '@src/store/services/auth-service'
import style from './notifications.module.scss'

export const Notification: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const isPageFocus = usePageFocus()
  const { data: user } = authApi.useGetMeQuery()
  const pageQuery = useQuery('page')
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
  })

  const { data, isLoading, refetch } = notificationApi.useGetListQuery(
    {
      page: paramsFilters.page,
      sort: ['send_at:desc'],
    },
    {
      pollingInterval: 60000,
      skip: !user || user.role !== UserRoles.SalesManager,
      refetchOnFocus: true,
    },
  )

  notificationApi.useGetReadNotificationQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  const onCell = useCallback((record: NotificationType) => ({
    onClick: () => {
      if (record.data.company_name) {
        history.push(`/companies/view/${record.data.company_id}`)
      }
    },
  }), [history])

  useEffect(() => {
    if (isPageFocus && user?.role === UserRoles.SalesManager) {
      refetch()
    }
  }, [isPageFocus, user, refetch])

  const [makeRead, { isLoading: isReadLoading }] = notificationApi.useMakeReadMutation()

  const handleMarkAsRead = useCallback((recordId: any) => {
    const currentTime = moment().format('YYYY-MM-DD HH:mm')
    makeRead({ id: recordId, read_at: currentTime })
  }, [makeRead])

  const columns = useMemo<ColumnProps<NotificationType>[]>(() => [
    {
      title: '#',
      dataIndex: 'index',
      render: (_, record, index) => index + 1 + ((paramsFilters.page - 1) * 10),
      onCell,
    },
    {
      title: t('notifications.columns.text'),
      dataIndex: 'data',
      render: (item) => item.content,
      onCell,
    },
    {
      title: t('notifications.columns.companyName'),
      dataIndex: 'data',
      render: (item) => item.company_name,
      onCell,
    },
    {
      title: t('notifications.columns.sendAt'),
      dataIndex: 'data',
      render: (item) => moment.utc(item.send_at).local().format('YYYY-MM-DD HH:mm'),
      onCell,
    },
    {
      dataIndex: 'actions',
      fixed: 'right',
      width: 100,
      render: (_, record) => (
        <div className={style.actions}>
          {record.read_at ? (
            <div
              className={cn(style.actions_item, style.actions_item__isRead)}
            >
              <CheckOutlined />
            </div>
          ) : (
            <Popconfirm
              placement="left"
              title={t('notifications.columns.isReadModal')}
              onConfirm={() => handleMarkAsRead(record.id)}
              okText={t('popconfirm.okText')}
              cancelText={t('popconfirm.cancelText')}
              disabled={isReadLoading}
            >
              <button
                type="button"
                className={cn(style.actions_item, style.actions_item__isNotRead)}
                disabled={isReadLoading}
              >
                <CheckCircleOutlined />
              </button>
            </Popconfirm>
          )}
        </div>
      ),
    },
  ], [onCell, t, paramsFilters.page, isReadLoading, handleMarkAsRead])

  return (
    <div>
      <PageTable
        title={t('notifications.title')}
        setParamsFilters={setParamsFilters}
        paramsFilters={paramsFilters}
        tableData={{
          data: data?.notifications || [],
          isLoading,
          columns,
        }}
      />
    </div>

  )
}
