import { ReactNode, useEffect, useMemo, useState } from 'react'
import { NavLink, useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Card, Col, Descriptions, Popconfirm, Row } from 'antd'
import moment from 'moment/moment'

import { companiesApi } from '@src/store/services/companies-service'
import { CompanyType } from '@src/types/company'
import { PageTable } from '@src/components/Table/PageTable'
import { useAgreementsTable } from '@src/components/pages/agreements/AgreementsTable.hook'
import { CustomerWorkers } from '@src/components/CustomerWorkers/CustomerWorkers'
import { authApi } from '@src/store/services/auth-service'
import { UserRoles } from '@src/types/users'
import { TaskComments } from '@src/components/pages/tasks/TaskComments'
import { inquiriesApi } from '@src/store/services/inquiries-service'
import useQuery from '@src/hooks/useQuery'

export const CompaniesView = () => {
  const { t } = useTranslation()
  const filtersQuery = useQuery('filters')
  const params = useParams<{ id: string }>()
  const history = useHistory()
  const [activeTabKey, setActiveTabKey] = useState<string>('customerWorkers')
  const { data, isLoading } = companiesApi.useGetOneCompanyQuery(+params.id)
  const { data: user } = authApi.useGetMeQuery()
  const { columns, data: dataAgreements, paramsFilters, setParamsFilters, isLoading: isLoadingAgreements } = useAgreementsTable({
    initParamsFilters: {
      page: 1,
      filters: {
        [data?.type === CompanyType.Owner ? 'owner_id' : 'customer_id']: {
          $eq: params.id,
        },
      },
    },
    skip: user?.role !== UserRoles.Admin,
  })

  const [deleteCompany, { isLoading: isLoadingDelete }] = companiesApi.useDeleteCompanyMutation()

  const { data: inquiries } = inquiriesApi.useListQuery({
    filters: filtersQuery ? JSON.parse(filtersQuery) : {
      company_id: {
        $eq: +params.id,
      },
    },
    sort: ['created_at:desc'],
  })

  useEffect(() => {
    setParamsFilters({
      page: 1,
      filters: {
        [data?.type === CompanyType.Owner ? 'owner_id' : 'customer_id']: {
          $eq: params.id,
        },
      },
    })
  }, [data?.type, params.id, setParamsFilters])

  const contentList: Record<string, ReactNode> = useMemo(() => ({
    agreements: user?.role === UserRoles.Admin ? (
      <PageTable
        isTableOnly
        setParamsFilters={setParamsFilters}
        paramsFilters={paramsFilters}
        tableData={{
          ...dataAgreements,
          isLoading: isLoadingAgreements,
          columns,
          components: {
            body: {
              row: (props: any) => <tr {...props} style={{ background: dataAgreements?.data.find((item) => item.id === props['data-row-key'])?.owner.color }} />,
            },
          },
        }}
      />
    ) : null,
    customerWorkers: (
      <CustomerWorkers companyId={+params.id} />
    ),
    chat: (
      <TaskComments taskId={+params.id} inquiries={inquiries?.data!} />
    ),
  }), [columns, dataAgreements, isLoadingAgreements, inquiries, params.id, paramsFilters, setParamsFilters, user])

  const handleDelete = async () => {
    const result = await deleteCompany(+params.id)

    if ('data' in result) {
      history.goBack()
    }
  }

  const tabList = useMemo(() => {
    if (user?.role === UserRoles.Admin) {
      return [
        { key: 'customerWorkers', label: t('customerWorkers.title') },
        { key: 'agreements', label: t('agreements.title') },
        { key: 'chat', label: t('chat.title') },
      ]
    }

    if (user?.role === UserRoles.SalesManager) {
      setActiveTabKey('chat')
      return [
        { key: 'customerWorkers', label: t('customerWorkers.title') },
        { key: 'chat', label: t('chat.title') },
      ]
    }

    return [
      { key: 'customerWorkers', label: t('customerWorkers.title') },
    ]
  }, [t, user])

  return (
    <div>
      <Row gutter={[12, 12]}>
        <Col lg={24} xl={8}>
          <Card
            loading={isLoading}
            actions={[
              <NavLink
                to={data?.type === CompanyType.Owner ? `/owners/edit/${params.id}` : `/customers/edit/${params.id}`}
              >
                <EditOutlined key="edit" />
              </NavLink>,
              <Popconfirm
                placement="left"
                title={t('popconfirm.deleteTitle')}
                onConfirm={handleDelete}
                okText={t('popconfirm.okText')}
                cancelText={t('popconfirm.cancelText')}
                disabled={isLoadingDelete}
              >
                <button
                  type="button"
                  disabled={isLoadingDelete}
                >
                  <DeleteOutlined />
                </button>
              </Popconfirm>,
            ]}
          >
            {data && (
              <Descriptions
                title={data.name}
                column={1}
                items={[
                  { label: t('companies.columns.code'), children: data.company_code },
                  { label: t('companies.columns.address'), children: data.address },
                  { label: t('companies.columns.accountNumber'), children: data.account_number },
                  { label: t('companies.columns.agreementNumber'), children: data.agreement_number },
                  { label: t('companies.columns.createdAt'), children: moment(data.created_at).format('YYYY-MM-DD') },
                  { label: t('companies.columns.vatNumber'), children: data.vat_number },
                  { label: t('companies.columns.fromService'), children: data.from_service ? t('common.true') : t('common.false') },
                ]}
              />
            )}
          </Card>
        </Col>
        <Col lg={24} xl={16}>
          <Card
            activeTabKey={activeTabKey}
            onTabChange={setActiveTabKey}
            tabList={tabList}
            tabProps={{
              size: 'middle',
            }}
          >
            {contentList[activeTabKey]}
          </Card>
        </Col>
      </Row>
      {/* {AgreementsComponents} */}
    </div>
  )
}
