export enum SendEmailType {
  AgreementBills = 'agreementBills',
  AgreementInvoices = 'agreementInvoices',
  AgreementInvoicesV2 = 'agreementInvoiceV2',
  Agreements = 'agreements',
  AgreementOrder = 'agreementOrder',
  Invoices = 'invoices',
  TransportationBills = 'transportationBills',
  Debts = 'debts',
  ServiceInvoice = 'serviceInvoice',
  DetailsOffers = 'detailsOffers'
}

export type MailLogsType = {
  id: number
  document_type: DocumentType
  customer_name: string
  customer_email: string
  status: MailLogsStatusType
  created_at: string
}

export const MAIL_LOGS_STATUS = {
  SUCCESS: 'success',
  FAILED: 'failed',
  SENDING: 'sending',
} as const

export type MailLogsStatusType = (typeof MAIL_LOGS_STATUS)[ keyof typeof MAIL_LOGS_STATUS]

export const DOCUMENT_TYPE = {
  AGREEMENT: 'Agreement',
  AGREEMENT_INVOICE: 'AgreementInvoice',
  AGREEMENT_INVOICE_V2: 'AgreementInvoiceV2',
  AGREEMENT_BILL: 'AgreementBill',
  AGREEMENT_CREATED: 'AgreementCreated',
  BUSINESS_INVOICE: 'BusinessInvoice',
  DEBT: 'Debt',
  TRANSPORTATION_BILL: 'TransportationBill',
} as const

export type DocumentType = (typeof DOCUMENT_TYPE)[ keyof typeof DOCUMENT_TYPE]
