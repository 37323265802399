import { useFormikContext } from 'formik'
import { Button } from 'antd'
import { PrinterOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { agreementsApi } from '@src/store/services/agreements-service'
import { useMemo } from 'react'
import moment from 'moment'
// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import n2words from 'n2words'
import { customRound } from '@src/lib/converter'
import { companiesApi } from '@src/store/services/companies-service'
import { getLithuanianName } from '@src/constants/rentalTerm'

export const AgreementInvoicesDocument = () => {
  const { t } = useTranslation()
  const { values } = useFormikContext<any>()
  const { data: order } = agreementsApi.useGetOneAgreementOrderQuery(values.orderId, { skip: !values.orderId })
  const { data: agreement } = agreementsApi.useGetOneAgreementQuery(order?.agreement_id, { skip: !order?.agreement_id })

  const { data: company } = companiesApi.useGetOneCompanyQuery(values.company_id, { skip: !values.company_id })

  const pvm = useMemo(() => (values.vat_calculated ? ((+values.price || 0) * 0.21).toFixed(2) : 0), [values])
  const total = useMemo(() => (+pvm + +values.price).toFixed(2), [values, pvm])

  /* useEffect(() => {
    const invoicePeriodDays = moment(values.date_end).diff(values.date_start, 'days') + 1

    const daysInMonth = moment(values.date_end).endOf('month').date()
    const isFullMonth = invoicePeriodDays === daysInMonth

    if (values.unit === 'months' && isFullMonth) {
      setUnit('months')
      setQuantity(1)
      setUnitPrice(values.price)
    } else {
      setUnit('days')
      const newQuantity = calculateLeaseTerm(values.date_start, values.date_end)
      setQuantity(newQuantity)
      setUnitPrice(newQuantity === 0 ? values.price : values.price / newQuantity)
    }
  }, [values]) */

  return (
    <>
      <div className="agreement_invoices_bills_document" id="printable-block">
        <p className="font-bold" style={{ textAlign: 'center', textTransform: 'uppercase' }}>PVM SĄSKAITA-FAKTŪRA</p>
        <p className="text" style={{ textAlign: 'center' }}>
          Serija SEN Nr.
          {' '}
          {values?.bill_number || `${moment(order?.created_at).format('YYYY-MM-DD')} / ${order?.id}`}
        </p>

        <table style={{ fontSize: 13, tableLayout: 'fixed', width: '100%' }}>
          <tbody>
            <tr>
              <td valign="top" style={{ width: '50%', minWidth: '50%', verticalAlign: 'top' }}>
                Pardavėjas
                {' '}
                <br />
                <strong>{agreement?.owner?.name}</strong>
                {' '}
                <br />
                {agreement?.owner?.address}
                {' '}
                <br />
                Įmonės kodas
                {' '}
                {agreement?.owner?.company_code}
                {' '}
                <br />
                PVM mok.kodas
                {' '}
                {agreement?.owner?.vat_number}
                {' '}
                <br />
                A/s
                {' '}
                {agreement?.owner?.account_number}
              </td>
              <td valign="top" style={{ width: '50%', minWidth: '50%', verticalAlign: 'top' }}>
                Pirkėjas
                {' '}
                <br />
                <strong>{company?.name}</strong>
                {' '}
                <br />
                {company?.address}
                {' '}
                <br />
                Įmonės kodas
                {' '}
                {company?.company_code}
                {' '}
                <br />
                PVM mok.kodas
                {' '}
                {company?.vat_number}
              </td>
            </tr>
          </tbody>
        </table>
        <p className="text" style={{ textAlign: 'center' }}>
          Data:
          {' '}
          {moment(values.created_at).format('YYYY-MM-DD')}
        </p>
        <p className="text">
          Apmokėti iki:
          {' '}
          {moment(values.pay_by).format('YYYY-MM-DD')}
        </p>
        <table className="table" style={{ width: '100%', fontSize: 13 }}>
          <tbody>
            <tr className="row">
              <td
                className="col"
                style={{ width: '5%', fontWeight: 700, textAlign: 'center' }}
              >
                Nr.
              </td>
              <td className="col" style={{ width: '33.5%', fontWeight: 700 }}>
                Pavadinimas
              </td>
              <td
                className="col"
                style={{ width: '15%', fontWeight: 700, textAlign: 'center' }}
              >
                Mato vnt.
              </td>
              <td
                className="col"
                style={{ width: '10%', fontWeight: 700, textAlign: 'center' }}
              >
                Kiekis
              </td>
              <td
                className="col"
                style={{ width: '17.5%', fontWeight: 700, textAlign: 'center' }}
              >
                Kaina EUR
              </td>
              <td
                className="col"
                style={{ width: '19%', fontWeight: 700, textAlign: 'center' }}
              >
                Suma EUR
              </td>
            </tr>
            <tr className="row">
              <td
                className="col"
                style={{ width: '5%', fontWeight: 400, textAlign: 'center' }}
              >
                1.
              </td>
              <td className="col" style={{ width: '33.5%', fontWeight: 400 }}>
                Nuoma
                {' '}
                {order?.car.make}
                {' '}
                {order?.car.model}
                {' '}
                {order?.car.serial_number}
                <br />
                {moment(values.date_start).format('YYYY-MM-DD')}
                {' '}
                {moment(values.date_end).format('YYYY-MM-DD')}
                <br />
                {values.unit_text}
              </td>
              <td
                className="col"
                style={{ width: '15%', fontWeight: 400, textAlign: 'center' }}
              >
                {getLithuanianName(values.unit)}
              </td>
              <td
                className="col"
                style={{ width: '10%', fontWeight: 400, textAlign: 'center' }}
              >
                {values.quantity}
              </td>
              <td
                className="col"
                style={{ width: '17.5%', fontWeight: 400, textAlign: 'center' }}
              >
                {(+values.unit_price).toFixed?.(2)}
              </td>
              <td
                className="col"
                style={{ width: '19%', fontWeight: 400, textAlign: 'center' }}
              >
                {(+values?.price).toFixed?.(2)}
              </td>
            </tr>
          </tbody>
        </table>

        <table style={{ fontSize: 13, width: '100%' }}>
          <tbody>
            <tr style={{ width: '100%' }}>
              <td style={{ width: '20%', color: '#ffffff' }}>.</td>
              <td style={{ width: '35%' }}>Iš viso:</td>
              <td style={{ width: '45%', textAlign: 'right' }}>
                {values.price}
                {' '}
                EUR
              </td>
            </tr>
            <tr style={{ width: '100%' }}>
              <td style={{ width: '20%', color: '#ffffff' }}>.</td>
              <td style={{ width: '35%' }}>
                {!!values?.vat_calculated && '21%'}
              </td>
              <td style={{ width: '45%', textAlign: 'right' }}>
                {pvm}
                {' '}
                EUR
              </td>
            </tr>
            <tr style={{ width: '100%' }}>
              <td style={{ width: '20%', color: '#ffffff' }}>.</td>
              <td style={{ width: '35%' }}>Bendra suma</td>
              <td style={{ width: '45%', textAlign: 'right' }}>
                {total}
                {' '}
                EUR
              </td>
            </tr>
          </tbody>
        </table>
        <p className="text">
          Iš viso
          {' '}
          {values.vat_calculated ? 'su' : 'be'}
          {' '}

          PVM:
          {' '}
          {n2words(customRound(+total), { lang: 'lt' })}
          {' EUR, '}
          {total.split('.')[1]}
          ct.
        </p>
        <p className="text">{values.text}</p>
        <p className="text">
          <span style={{ width: '100%', display: 'inline-block' }}>
            <span style={{ float: 'left', width: '17%' }}>Sąskaitą išrašė:</span>
            <span
              style={{
                borderBottom: 'solid 1px #000000',
                width: '83%',
                display: 'block',
                marginLeft: '17%',
              }}
            >
              <span style={{ color: '#ffffff', opacity: 0 }}>empty input</span>
            </span>
          </span>
          <br />
          <span
            className="text"
            style={{ textAlign: 'center', display: 'inline-block', width: '100%' }}
          >
            (vardas, pavardė, pareigos)
          </span>
        </p>
        <p className="text">
          <span style={{ width: '100%', display: 'inline-block' }}>
            <span style={{ float: 'left', width: '20%' }}>Sąskaitą gavo:</span>
            <span
              style={{
                borderBottom: 'solid 1px #000000',
                width: '80%',
                display: 'block',
                marginLeft: '20%',
              }}
            >
              <span style={{ color: '#ffffff', opacity: 0 }}>empty input</span>
            </span>
          </span>
          <br />
          <span
            className="text"
            style={{ textAlign: 'center', display: 'inline-block', width: '100%' }}
          >
            (vardas, pavardė, pareigos)
          </span>
        </p>
      </div>
      <div className="documentActions">
        <Button icon={<PrinterOutlined />} onClick={window.print}>{t('settings.print')}</Button>
      </div>
    </>
  )
}
