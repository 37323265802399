import { FC, useCallback, useMemo, ReactElement, useState } from 'react'
import { useField, useFormikContext } from 'formik'
import { Select } from 'antd'
import style from '@src/components/FieldFormik/FieldFormik.module.scss'
import cn from 'classnames'

const { Option } = Select

type SelectFormikProps = {
  name: string
  options: Array<{ value: string | number, label: any }>
  placeholder?: string
  disabled?: boolean
  showSearch?: boolean
  required?: boolean
  onChange?: (value: string | number | Array<string | number>, current: any) => void
  onSearch?: (value: string | number) => void
  isMultiple?: boolean
  dropdownRender?: (menu: ReactElement) => ReactElement
  width?: string
}

export const SelectFormik: FC<SelectFormikProps> = ({
  name, options, showSearch = true, placeholder, disabled, required, onChange, onSearch, isMultiple, dropdownRender, width,
}) => {
  const { setFieldValue } = useFormikContext<any>()
  const [field, meta] = useField(name)
  const [showPlaceholder, setShowPlaceholder] = useState(true)

  const isError = useMemo(() => meta.error && meta.touched, [meta])

  const handleChange = useCallback((value: any, current: any) => {
    setFieldValue(name, value)

    if (onChange) onChange(value, current)
  }, [name, onChange, setFieldValue])

  return (
    <div className={style.wrapper} style={{ width }}>
      <Select
        showSearch={showSearch}
        onSearch={onSearch}
        style={{ width: '100%' }}
        optionFilterProp="children"
        placeholder={(
          <>
            {placeholder}
            {' '}
            {required && <span className={style.placeholder_required}>*</span>}
          </>
        )}
        disabled={disabled}
        onChange={handleChange}
        value={isMultiple ? field.value || [] : field.value || null} // Обратите внимание на это изменение
        size="large"
        className={cn({ [style.errorBorder]: isError })}
        mode={isMultiple ? 'multiple' : undefined}
        dropdownRender={dropdownRender}
        onDropdownVisibleChange={(open) => {
          setShowPlaceholder(!open)
        }}
      >
        {options.map((option) => (
          <Option {...option} key={option.value} value={option.value}>{option.label}</Option>
        ))}
      </Select>
      {placeholder && showPlaceholder && (
        <div
          className={
            cn(
              style.placeholder,
              field.value && style.placeholder_active,
            )
          }
        >
          {placeholder}
        </div>
      )}
      {isError && <div className={style.error}>{meta.error}</div>}
    </div>
  )
}
