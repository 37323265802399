import { ArrowUpOutlined, EditOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { formatPrice } from '@src/lib/formatPrice'
import { detailsApi } from '@src/store/services/details-service'
import { Button, Card, Col, Descriptions, Modal, Row } from 'antd'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { authApi } from '@src/store/services/auth-service'
import { UserRoles } from '@src/types/users'
import { DetailsViewContent } from './DetailsViewContent'
import { DetailsAddModal } from './DetailsAddModal'
import { DetailsTransferModal } from './DetailsTransferModal'

export const DetailsView = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams<{ id: string }>()
  const [activeTabKey, setActiveTabKey] = useState<string>('detailsHistory')
  const { data, isLoading: isLoadingGetOne } = detailsApi.useGetOneDetailQuery(+params.id)
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false)
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState<boolean>(false)
  const [isTransferModalOpen, setIsTransferModalOpen] = useState<boolean>(false)
  const { data: user } = authApi.useGetMeQuery()

  const hasAccess = useMemo(() => user?.role === UserRoles.Admin || user?.role === UserRoles.ServiceManager || user?.role === UserRoles.StoreManager, [user])

  const onCloseChangeModal = () => {
    setIsAddModalOpen(false)
    setIsRemoveModalOpen(false)
  }

  return (
    <div>
      <Row gutter={[12, 12]}>
        <Col lg={24} xl={8}>
          <Card
            loading={isLoadingGetOne}
            actions={hasAccess ? [
              <Button onClick={() => history.push(`/details/edit/${params.id}`)}>
                <EditOutlined key="edit" />
              </Button>,
              <Button onClick={() => setIsRemoveModalOpen(true)}>
                <MinusOutlined key="remove_detail" />
              </Button>,
              <Button onClick={() => setIsAddModalOpen(true)}>
                <PlusOutlined key="add_detail" />
              </Button>,
              <Button onClick={() => setIsTransferModalOpen(true)}>
                <ArrowUpOutlined key="transfer" />
              </Button>,
            ] : undefined}
          >
            {data && (
              <Descriptions
                title={`${data.name}`}
                column={1}
                items={[
                  { label: t('details.columns.serialNumber'), children: data.serial_number },
                  { label: t('details.columns.count'), children: data.count },
                  { label: t('details.columns.in_mechanic'), children: data.storage_count },
                  { label: t('details.columns.total_count'), children: data.total_count },
                  { label: t('details.columns.price'), children: formatPrice(data.price) },
                  { label: t('details.columns.total_price'), children: data.total_count >= 0 ? formatPrice(data.price * data.total_count) : t('cars.columns.no_price') },
                  { label: t('details.columns.place'), children: data.area ? `${data.area.stock.name} / ${data.area.stock.address} / ${data.area.name}` : '' },
                ]}
              />
            )}
          </Card>
        </Col>
        <Col lg={24} xl={16}>
          <Card
            loading={isLoadingGetOne}
            activeTabKey={activeTabKey}
            onTabChange={setActiveTabKey}
            tabList={[
              {
                key: 'detailsHistory',
                label: t('details.columns.history'),
              },
              {
                key: 'detailsStorage',
                label: t('details.columns.storage'),
              },
            ]}
            tabProps={{
              size: 'middle',
            }}
          >
            {data && (
            <DetailsViewContent
              activeTabKey={activeTabKey}
              detailId={+params.id}
              storages={data.storages}
            />
            )}
          </Card>
        </Col>
      </Row>
      {data && (

      <Modal
        open={isAddModalOpen || isRemoveModalOpen}
        closeIcon
        onCancel={onCloseChangeModal}
        centered
        title={
          (
            <div style={{ marginBottom: '16px' }}>
              {isRemoveModalOpen ? t('details.remove_detail') : t('details.add_detail')}
            </div>
          )
        }
        footer={null}
        width={400}
        destroyOnClose
      >
        <DetailsAddModal
          detailId={data.id}
          onClose={onCloseChangeModal}
          isRemove={isRemoveModalOpen}
        />
      </Modal>
      )}

      {data && (
        <Modal
          open={isTransferModalOpen}
          closeIcon
          onCancel={() => setIsTransferModalOpen(false)}
          centered
          title={
          (
            <div style={{ marginBottom: '16px' }}>
              {t('details.transfer_detail')}
            </div>
          )
        }
          footer={null}
          destroyOnClose
        >
          <DetailsTransferModal
            detailId={data.id}
            setIsTransferModalOpen={setIsTransferModalOpen}
            stock={data.count}
          />
        </Modal>
      )}
    </div>
  )
}
