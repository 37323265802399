import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RollbackOutlined } from '@ant-design/icons'
import { NavLink } from 'react-router-dom'
import { ColumnProps } from 'antd/lib/table'
import { Button, Tag } from 'antd'
import useQuery from '@src/hooks/useQuery'
import { PageTable } from '@src/components/Table/PageTable'
import { detailsApi } from '@src/store/services/details-service'
import { UserDetailType, DetailTransferRequest, REQUEST_STATUSES } from '@src/types/details'
import { TableFiltersType } from '@src/types/tableFilters'
import { authApi } from '@src/store/services/auth-service'
import style from './my-details.module.scss'

type DetailsFilters = {
  page: number,
  filters: {
    name: {
      $contains: string
    },
    serialNumbers: {
      serial_number: {
        $contains: string
      }
    }
  }
}

export const MyDetails = () => {
  const { t } = useTranslation()
  const pageQuery = useQuery('page')
  const [activeTabKey, setActiveTabKey] = useState<string>('all')
  const { data: user } = authApi.useGetMeQuery()
  const [detailsParamsFilters, setDetailsParamsFilters] = useState<DetailsFilters>(
    {
      page: pageQuery ? +pageQuery : 1,
      filters: {
        name: {
          $contains: '',
        },
        serialNumbers: {
          serial_number: {
            $contains: '',
          },
        },
      },
    },
  )
  const [requestsDetailsParamsFilters, setRequestsDetailsParamsFilters] = useState(
    {
      page: pageQuery ? +pageQuery : 1,
      filters: {
        detail: {
          name: {
            $contains: '',
          },
          serialNumbers: {
            serial_number: {
              $contains: '',
            },
          },
        },
      },
    },
  )
  const { data, isLoading } = detailsApi.useGetUserDetailsListQuery({ id: user?.id, ...detailsParamsFilters }, { skip: !user?.id || activeTabKey !== 'all', refetchOnMountOrArgChange: true })
  const { data: requestsData, isLoading: isLoadingRequests } = detailsApi.useGetDetailsRequestsQuery(requestsDetailsParamsFilters, { skip: activeTabKey === 'all', refetchOnMountOrArgChange: true })
  const [acceptDetailTransferRequest, { isLoading: isLoadingAcceptRequest }] = detailsApi.useAcceptDetailTransferRequestMutation()
  const [rejectDetailTransferRequest, { isLoading: isLoadingRejectRequest }] = detailsApi.useRejectDetailTransferRequestMutation()

  const acceptRequest = useCallback(async (id: number) => {
    const result = await acceptDetailTransferRequest(id)

    if ('data' in result) {
      setActiveTabKey('all')
    }
  }, [acceptDetailTransferRequest])

  const rejectRequest = useCallback(async (id: number) => {
    const result = await rejectDetailTransferRequest(id)

    if ('data' in result) {
      setActiveTabKey('all')
    }
  }, [rejectDetailTransferRequest])

  const columns = useMemo<ColumnProps<UserDetailType>[]>(() => [
    {
      title: t('details.columns.index'),
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => ((index + 1) + ((detailsParamsFilters.page - 1) * (data?.meta.pageSize || 0))),
    },
    {
      title: t('details.columns.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('details.columns.serialNumber'),
      dataIndex: 'serial_number',
      key: 'serial_number',
    },
    {
      title: t('details.columns.quantity'),
      dataIndex: 'storages',
      key: 'count',
      render: (storages) => storages[0].count,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      render: (_, record) => (
        <NavLink to={`/mechanic/detail-transfer?id=${record.id}`} className={style.actions}>
          <RollbackOutlined />
        </NavLink>
      ),
    },
  ], [data, detailsParamsFilters, t])

  const requestsColumns = useMemo<ColumnProps<DetailTransferRequest>[]>(() => [
    {
      title: t('details.columns.index'),
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => ((index + 1) + ((detailsParamsFilters.page - 1) * (data?.meta.pageSize || 0))),
    },
    {
      title: t('details.columns.name'),
      dataIndex: ['detail', 'name'],
      key: 'name',
    },
    {
      title: t('details.columns.serialNumber'),
      dataIndex: ['detail', 'serial_number'],
      key: 'serial_number',
    },
    {
      title: t('details.columns.from'),
      dataIndex: ['from', 'name'],
      key: 'worker',
      render: (_, record) => `${record.from.name} ${record.from.surname}`,
    },
    {
      title: t('details.columns.to'),
      dataIndex: ['to', 'name'],
      key: 'to',
      render: (_, record) => `${record.to.name} ${record.to.surname}`,
    },
    {
      title: t('details.columns.unit'),
      dataIndex: ['detail', 'unit'],
      key: 'unit',
    },
    {
      title: t('details.columns.quantity'),
      dataIndex: 'count',
      key: 'count',
    },
    {
      title: t('details.columns.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        const TAG_COLOR = {
          [REQUEST_STATUSES.Created]: 'warning',
          [REQUEST_STATUSES.Accepted]: 'success',
          [REQUEST_STATUSES.Rejected]: 'error',
          [REQUEST_STATUSES.Closed]: 'default',
        }

        return <Tag style={{ textTransform: 'capitalize' }} color={TAG_COLOR[status as (typeof REQUEST_STATUSES)[keyof typeof REQUEST_STATUSES]]}>{status}</Tag>
      },
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      render: (_, record) => (
        record.status === REQUEST_STATUSES.Created && record.to_id === user?.id && (
        <div className={style.actions}>
          <Button type="primary" onClick={() => acceptRequest(record.id)}>{t('popconfirm.okText') || ''}</Button>
          <Button danger onClick={() => rejectRequest(record.id)}>{t('popconfirm.cancelText') || ''}</Button>
        </div>
        )
      ),
    },
  ], [acceptRequest, data?.meta.pageSize, detailsParamsFilters.page, rejectRequest, t, user?.id])

  const filtersList = useMemo<TableFiltersType>(() => [
    {
      type: 'input',
      dataIndex: 'name',
      placeholder: t('details.columns.name'),
      width: '300px',
      operator: '$contains',
    },
    {
      type: 'input',
      dataIndex: 'serialNumbers.serial_number',
      placeholder: t('details.columns.serialNumber'),
      width: '300px',
      operator: '$contains',
    },
  ], [t])

  const filtersListRequests = useMemo<TableFiltersType>(() => [
    {
      type: 'input',
      dataIndex: 'detail.name',
      placeholder: t('details.columns.name'),
      width: '300px',
      operator: '$contains',
    },
    {
      type: 'input',
      dataIndex: 'detail.serialNumbers.serial_number',
      placeholder: t('details.columns.serialNumber'),
      width: '300px',
      operator: '$contains',
    },
  ], [t])

  const tabList = useMemo(() => [
    {
      key: 'all',
      label: t('myDetails.all')!,
    }, {
      key: 'requests',
      label: t('myDetails.requests')!,
    },
  ], [t])

  const tableMetaData = useMemo(() => {
    if (activeTabKey === 'requests') {
      return ({
        setParamsFilters: setRequestsDetailsParamsFilters,
        paramsFilters: requestsDetailsParamsFilters,
        tableData: {
          ...requestsData,
          isLoading: isLoadingRequests,
          columns: requestsColumns,
        },
        filtersList: filtersListRequests,
      })
    }

    return ({
      setParamsFilters: setDetailsParamsFilters,
      paramsFilters: detailsParamsFilters,
      tableData: {
        ...data,
        isLoading: isLoading || isLoadingAcceptRequest || isLoadingRejectRequest,
        columns,
      },
      filtersList,
    })
  }, [activeTabKey, detailsParamsFilters, data, isLoading, isLoadingAcceptRequest, isLoadingRejectRequest, columns, filtersList, requestsDetailsParamsFilters, requestsData, isLoadingRequests, requestsColumns, filtersListRequests])

  return (
    <PageTable
      title={t('menu.myDetails')!}
      onTabChange={setActiveTabKey}
      activeTabKey={activeTabKey}
      tabList={tabList}
      filtersV2
      createText={t('getDetail.addTitle')}
      createLink="/mechanic/get-my-detail"
      {...tableMetaData}
    />
  )
}
