import { PageTable } from '@src/components/Table/PageTable'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useQuery from '@src/hooks/useQuery'
import { stocksApi } from '@src/store/services/stocks-service'
import { ColumnProps } from 'antd/lib/table'
import { StockType } from '@src/types/stocks'
import { NavLink, useHistory } from 'react-router-dom'
import style from '@src/Pages/Invoices/invoices.module.scss'
import cn from 'classnames'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Popconfirm } from 'antd'
import { addQuery } from '@src/lib/route'
import { isSelectionText } from '@src/lib/utils'

export const Stocks = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const pageQuery = useQuery('page')
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
  })
  const { data, isLoading } = stocksApi.useGetStocksListQuery(paramsFilters)
  const [remove, { isLoading: isLoadingRemove }] = stocksApi.useDeleteStockMutation()

  useEffect(() => {
    history.push({
      search: addQuery({
        ...paramsFilters,
      }),
    })
  }, [paramsFilters, history])

  const onCell = useCallback((record: StockType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/stock-areas/${record.id}`)
      }
    },
  }), [history])

  const columns = useMemo<ColumnProps<StockType>[]>(() => [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => (index + 1 + ((paramsFilters.page - 1) * (data?.meta.pageSize || 0))),
      onCell,
    },
    {
      title: t('stocks.columns.name'),
      dataIndex: 'name',
      key: 'name',
      onCell,
    },
    {
      title: t('stocks.columns.address'),
      dataIndex: 'address',
      key: 'address',
      onCell,
    },
    {
      title: t('stocks.columns.notes'),
      dataIndex: 'notes',
      key: 'notes',
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 100,
      render: (_, record) => (
        <div className={style.actions}>
          <NavLink
            to={`/stocks/edit/${record.id}`}
            className={style.actions_item}
          >
            <EditOutlined />
          </NavLink>
          <Popconfirm
            placement="left"
            title={t('popconfirm.deleteTitle')}
            onConfirm={() => {
              remove(record.id)
            }}
            okText={t('popconfirm.okText')}
            cancelText={t('popconfirm.cancelText')}
            disabled={isLoadingRemove}
          >
            <button
              type="button"
              className={cn(style.actions_item, style.actions_item__delete)}
              disabled={isLoadingRemove}
            >
              <DeleteOutlined />
            </button>
          </Popconfirm>
        </div>
      ),
    },
  ], [data?.meta.pageSize, isLoadingRemove, onCell, paramsFilters.page, remove, t])

  return (
    <div>
      <PageTable
        title={t('stocks.title')}
        createLink="/stocks/create"
        setParamsFilters={setParamsFilters}
        paramsFilters={paramsFilters}
        tableData={{
          ...data,
          columns,
          isLoading,
        }}
      />
    </div>
  )
}
