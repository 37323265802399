import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal } from 'antd'
import { Form, Formik } from 'formik'
import cn from 'classnames'

import { tasksApi } from '@src/store/services/tasks-service'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { TaskCommentType } from '@src/types/tasks'
import { InquiriesDataType } from '@src/types/inquaries'
import { authApi } from '@src/store/services/auth-service'
import { UploadImages } from '@src/components/UploadImages/UploadImages'
import { Image } from '@src/components/ImagesPreview/Image'
import { inquiriesApi } from '@src/store/services/inquiries-service'
import { notificationApi } from '@src/store/services/notification-service'
import { DatePickerFormik } from '@src/components/DatePickerFormik/DatePickerFormik'
import * as Yup from 'yup'
import moment from 'moment'
import style from './taskComments.module.scss'

type TaskCommentsProps = {
  taskId: number
  comments?: TaskCommentType[]
  inquiries?: InquiriesDataType[]
  readonly?: boolean
}

export const TaskComments: FC<TaskCommentsProps> = ({ taskId, comments, readonly, inquiries }) => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [addTaskComment, { isLoading }] = tasksApi.useAddTaskCommentMutation()
  const [addInquiries] = inquiriesApi.useAddInquiriesMutation()
  const [addNotification, { isLoading: isNotificationLoading }] = notificationApi.useCreateMutation()
  const { data: user } = authApi.useGetMeQuery()

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      text: Yup.string().required(),
    })
  ), [])

  const modalValidationSchema = useMemo(() => (
    Yup.object().shape({
      notificationText: Yup.string().required(),
      send_at: Yup.string().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    inquiries: '',
    comment: '',
    images: [],
    notificationText: '',
    send_at: '',
    text: '',
  }), [])

  const onSubmit = async (values: any, resetForm: any) => {
    const formCommentData = new FormData()
    formCommentData.append('comment', values.comment)
    if (values.images?.length) {
      values.images.forEach((image: any) => {
        formCommentData.append('images[]', image.originFileObj)
      })
    }
    const formInquireData = new FormData()

    if (values.text) {
      formInquireData.append('text', values.text)
    }
    if (taskId) {
      formInquireData.append('company_id', taskId.toString())
    }
    if (user?.id) {
      formInquireData.append('user_id', user.id.toString())
    }

    if (isModalOpen) {
      await addNotification({
        text: values.notificationText,
        send_at: values.send_at.utc().format('YYYY-MM-DD HH:mm'),
        company_id: taskId,
      })
      setIsModalOpen(false)
      resetForm({ values: { ...values, notificationText: '', send_at: '' } })
      return
    }

    if (comments) {
      await addTaskComment({
        taskId,
        body: formCommentData,
      })
    } else {
      await addInquiries({
        body: formInquireData,
      })
      resetForm({ values: { text: '' } })
    }
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleCancel = (values: any, resetForm: any) => {
    resetForm({ values: { ...values, notificationText: '', send_at: '' } })
    setIsModalOpen(false)
  }

  const disablePastDates = (current: any) => current && current < moment().startOf('day')

  return (
    <div>
      {!readonly && (
        <Formik
          initialValues={initialValues}
          validationSchema={isModalOpen ? modalValidationSchema : validationSchema}
          onSubmit={(values, { resetForm }) => onSubmit(values, resetForm)}
        >
          {({ values, setFieldValue, submitForm, resetForm }) => (
            <Form className="not-card">
              {comments ? (
                <>
                  <FieldFormik
                    name="comment"
                    type="textarea"
                    autoSize={{ minRows: 1, maxRows: 10 }}
                    placeholder={t('tasks.comments.field')}
                  />
                  <UploadImages
                    fileList={values.images}
                    setFileList={(image: any) => {
                      setFieldValue('images', image).catch(() => {})
                    }}
                  />
                  <Button type="primary" htmlType="submit" loading={isLoading}>
                    {t('tasks.comments.save')}
                  </Button>
                </>
              ) : (
                <>
                  <FieldFormik
                    name="text"
                    type="textarea"
                    autoSize={{ minRows: 1, maxRows: 10 }}
                    placeholder={t('tasks.comments.text')}
                  />
                  <Modal
                    title={t('tasks.comments.createNotifications')}
                    open={isModalOpen}
                    onOk={submitForm}
                    onCancel={() => handleCancel(values, resetForm)}
                    confirmLoading={isNotificationLoading}
                  >
                    <FieldFormik
                      name="notificationText"
                      type="textarea"
                      autoSize={{ minRows: 1, maxRows: 10 }}
                      placeholder={t('tasks.comments.notification')}
                    />
                    <DatePickerFormik
                      name="send_at"
                      placeholder={t('notifications.sendAt') ?? ''}
                      disabledDate={disablePastDates}
                      showTime
                      format="YYYY-MM-DD HH:mm"
                    />
                  </Modal>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <Button type="primary" htmlType="submit" loading={isLoading}>
                      {t('tasks.comments.save')}
                    </Button>
                    <Button type="primary" onClick={() => showModal()} loading={isLoading}>
                      {t('tasks.comments.createNotifications')}
                    </Button>
                  </div>
                </>
              )}
            </Form>
          )}
        </Formik>
      )}
      {comments && (
        <div className={style.comments}>
          {comments
            ?.map((comment) => (
              <div key={comment.id} className={cn(style.comment, { [style.commentMe]: user?.id === comment.user_id })}>
                <div className={style.name}>
                  {comment.user.name}
                  {' '}
                  {comment.user.surname}
                </div>
                <div>{comment.comment}</div>
                {!!comment.images?.length && (
                  <div className={style.images}>
                    {comment.images.map((item: { file: any }) => (
                      <Image
                        url={`${process.env.REACT_APP_API_URL}/${item.file}`}
                        thumb={`${process.env.REACT_APP_API_URL}/${item.file}`}
                      />
                    ))}
                  </div>
                )}
              </div>
            ))
            .reverse()}
        </div>
      )}
      {inquiries && (
        <div className={style.comments}>
          {inquiries.map((inquirie) => (
            <div
              key={inquirie.id}
              className={cn(style.comment, { [style.commentMe]: user?.id === inquirie?.user?.id })}
            >
              <div className={style.name}>
                {inquirie.user?.name}
                {' '}
                {inquirie.user?.surname}
              </div>
              <div>{inquirie.text}</div>
              <div className={style.timestamp}>{moment(inquirie.created_at).format('YYYY.MM.DD HH:mm')}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
