import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { transportationApi } from '@src/store/services/transportation-service'
import { ColumnProps } from 'antd/lib/table'
import { TransportationBillStatus, TransportationBillType } from '@src/types/transportationBills'
import { Tag } from 'antd'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@src/store/store'
import style from '@src/Pages/TransportationBills/transportationBills.module.scss'
import { EuroOutlined, MailOutlined } from '@ant-design/icons'
import { setSendEmail } from '@src/store/ducks/app/reducer'
import { SendEmailType } from '@src/types/mailLogs'
import { isSelectionText } from '@src/lib/utils'

const statusColor: any = {
  1: 'blue',
  2: 'green',
  3: 'red',
}

type TransportationBillsActionsProps = {
  record: TransportationBillType
}

export const TransportationBillsActions: FC<TransportationBillsActionsProps> = ({ record }) => {
  const dispatch = useDispatch<AppDispatch>()

  return (
    <div className={style.actions}>
      {record.status !== TransportationBillStatus.notProcessed && (
        <a
          // eslint-disable-next-line max-len
          href={`${process.env.REACT_APP_API_URL}/api/transportations/generate-bill/${record.id}?access_token=${localStorage.getItem('token')}`}
          target="_blank"
          rel="noreferrer"
          className={style.actions_item}
        >
          <EuroOutlined />
        </a>
      )}
      {record.status === TransportationBillStatus.processed && record.company_id && (
        <button
          type="button"
          onClick={() => {
            dispatch(setSendEmail({
              type: SendEmailType.TransportationBills,
              companyId: record.company_id,
              id: record.id,
            }))
          }}
          className={style.actions_item}
        >
          <MailOutlined />
        </button>
      )}
    </div>
  )
}

export const useAgreementsTable = ({ initParamsFilters }: any) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch<AppDispatch>()
  const [paramsFilters, setParamsFilters] = useState(initParamsFilters)
  const { data, isLoading } = transportationApi.useGetListTransportationBillsQuery(paramsFilters)

  const onCellMailHandler = useCallback((record: TransportationBillType, isAccountant?: boolean) => ({
    onClick: () => {
      if (!isSelectionText()) {
        if (record.company_id || isAccountant) {
          dispatch(setSendEmail({
            type: SendEmailType.TransportationBills,
            companyId: record.company_id,
            id: record.id,
            isAccountant,
          }))
        }
      }
    },
  }), [dispatch])

  const onCell = useCallback((record: TransportationBillType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/transportation-bills/edit/${record.id}`, { backUrl: location.pathname + location.search })
      }
    },
  }), [history, location])

  const columns = useMemo<ColumnProps<TransportationBillType>[]>(() => [
    {
      title: t('transportationBills.columns.index'),
      dataIndex: 'index',
      key: 'index',
      width: 40,
      render: (_, record, index) => ((index + 1) + ((paramsFilters.page - 1) * (data?.meta.pageSize || 0))),
      onCell,
    },
    {
      title: t('transportationBills.columns.status'),
      dataIndex: 'status',
      key: 'status',
      width: 120,
      align: 'center',
      render: (status) => (
        <Tag color={statusColor[status]}>{t(`transportationBills.status.${status}`)}</Tag>
      ),
    },
    {
      title: t('transportation.columns.to'),
      dataIndex: 'transportation',
      key: 'name',
      width: 200,
      align: 'center',
      render: (transportation) => transportation.to.name,
      onCell,
    },
    {
      title: t('transportation.columns.transportationDate'),
      dataIndex: 'transportation',
      key: 'transportationDate',
      width: 120,
      align: 'center',
      render: (transportation) => transportation.date,
      onCell,
    },
    {
      title: t('transportation.columns.billNumber'),
      dataIndex: 'bill_number',
      key: 'bill_number',
      width: 120,
      align: 'center',
      render: (billNumber, record) => (
        <>
          {billNumber}
          <br />
          {(record.sum ? `(${record.sum}€)` : '')}
        </>
      ),
      onCell,
    },
    {
      title: t('transportation.columns.route'),
      dataIndex: 'transportation',
      key: 'route',
      align: 'center',
      render: (transportation) => (
        <>
          {transportation.address_from}
          <br />
          -
          <br />
          {transportation.address_to}
          <br />
          {transportation.distance != null ? `(${transportation.distance} km)` : ''}
        </>
      ),
      onCell,
    },
    {
      title: t('transportationBills.columns.sentAccountant'),
      dataIndex: 'is_sent_accountant',
      key: 'isSentAccountant',
      width: 120,
      align: 'center',
      render: (isSentAccountant) => (
        <Tag color={isSentAccountant ? 'green' : 'red'}>
          {t(`sendStatus.${isSentAccountant}`)}
        </Tag>
      ),
      onCell: (record) => onCellMailHandler(record, true),
    },
    {
      title: t('transportationBills.columns.sentCustomer'),
      dataIndex: 'is_sent_customer',
      key: 'isSentCustomer',
      width: 120,
      align: 'center',
      render: (isSentAccountant) => (
        <Tag color={isSentAccountant ? 'green' : 'red'}>
          {t(`sendStatus.${isSentAccountant}`)}
        </Tag>
      ),
      onCell: (record) => onCellMailHandler(record),
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      fixed: 'right',
      render: (_, record) => (
        <TransportationBillsActions record={record} />
      ),
    },
  ], [data, onCell, onCellMailHandler, paramsFilters.page, t])

  return {
    columns,
    data,
    isLoading,
    paramsFilters,
    setParamsFilters,
  }
}
