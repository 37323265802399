import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { formatFilters } from '@src/lib/formatFilters'
import { NotificationType, NotificationResponse } from '@src/types/notification'

export const notificationApi = createApi({
  reducerPath: 'notificationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      headers.set('Accept', 'application/json, text/plain, */*')
      return headers
    },
  }),
  refetchOnMountOrArgChange: 60,
  tagTypes: ['Notification'],
  endpoints: (build) => ({
    getList: build.query<NotificationResponse, { page: number, sort: [string] }>({
      query: (params) => ({
        url: `/notifications?${formatFilters(params)}`,
      }),
      providesTags: () => ['Notification'],
    }),
    getOne: build.query<NotificationType, string>({
      query: (id) => ({
        url: `/notification/${id}`,
      }),
      providesTags: () => ['Notification'],
    }),
    create: build.mutation<NotificationType, any>({
      query: (body) => ({
        url: '/notifications',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Notification'],
    }),
    makeRead: build.mutation<NotificationType, { id: string; read_at: string }>({
      query: ({ id, read_at }) => ({
        url: `/notifications/${id}/read`,
        method: 'PUT',
        body: { read_at },
      }),
      invalidatesTags: ['Notification'],
    }),
    getAllNotification: build.query<NotificationResponse, { page: number, filters: any }>({
      query: (params) => ({
        url: '/notifications?status=all',
        params: {
          page: params.page,
          ...params.filters?.filters?.reduce((acc: Record<string, any>, filter: any) => {
            // eslint-disable-next-line no-param-reassign
            acc[filter.columns] = filter.value
            return acc
          }, {}),
        },
      }),
      providesTags: () => ['Notification'],
    }),
    getReadNotification: build.query<NotificationResponse, void>({
      query: () => ({
        url: '/notifications',
        params: {
          status: 'unread',
        },
      }),
      providesTags: () => ['Notification'],
    }),
  }),
})
