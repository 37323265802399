import { Col, Row } from 'antd'
import { FC, useMemo } from 'react'
import { quizData } from '@src/components/pages/tasks/TaskEndWork/quizData'
import style from './taskEndWork.module.scss'

type TaskEndWorkQuizViewProps = {
  quiz?: string
}

export const TaskEndWorkQuizView: FC<TaskEndWorkQuizViewProps> = ({ quiz }) => {
  const quizObj = useMemo(() => (quiz ? JSON.parse(quiz) : undefined), [quiz])

  return (
    <Row gutter={[8, 8]}>
      {quizData.map((section) =>
        section.children.map((item) => (
          <Col key={item.name} xs={24} md={12} lg={8}>
            <div className={style.quiz}>
              <div className={style.quizQuestion}>{item.placeholder || section.title}</div>
              <div className={style.quizAnswer}>
                {quizObj?.[item.name]?.value}
                {quizObj?.[item.name]?.comment && (
                  <>
                    <div>Komentaras:</div>
                    <div>{quizObj?.[item.name]?.comment}</div>
                  </>
                )}
              </div>
            </div>
          </Col>
        )))}
    </Row>
  )
}
