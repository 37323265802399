import React, { useCallback, useMemo, useState } from 'react'
import { ColumnProps } from 'antd/lib/table'
import { Agreement } from '@src/types/agreements'
import cn from 'classnames'
import style from '@src/Pages/Agreements/agreements.module.scss'
import {
  DeleteOutlined,
  FileTextOutlined,
  MailOutlined,
} from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { Popconfirm } from 'antd'
import { useTranslation } from 'react-i18next'
import { agreementsApi } from '@src/store/services/agreements-service'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@src/store/store'
import { setSendEmail } from '@src/store/ducks/app/reducer'
import { SendEmailType } from '@src/types/mailLogs'
import { isSelectionText } from '@src/lib/utils'

export const useAgreementsTable = ({ initParamsFilters, skip }: any) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch<AppDispatch>()
  const [paramsFilters, setParamsFilters] = useState(initParamsFilters)
  const { data, isLoading } = agreementsApi.useGetListAgreementsQuery(paramsFilters, { skip })
  const [deleteAgreement, { isLoading: isLoadingDelete }] = agreementsApi.useDeleteAgreementMutation()
  const [generateDocument] = agreementsApi.useAgreementGenerateDocumentMutation()

  const onCell = useCallback((record: Agreement) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/agreements/edit/${record.id}`)
      }
    },
  }), [history])

  const columns = useMemo<ColumnProps<Agreement>[]>(() => [
    {
      title: t('agreements.columns.index'),
      dataIndex: 'index',
      key: 'index',
      width: 50,
      render: (_, record, index) => ((index + 1) + ((paramsFilters.page - 1) * (data?.meta.pageSize || 0))),
      onCell,
    },
    {
      title: t('agreements.columns.number'),
      dataIndex: 'agreement_number',
      key: 'agreement_number',
      width: 200,
      onCell,
    },
    {
      title: t('agreements.columns.customer'),
      dataIndex: 'customer',
      key: 'customer',
      width: 200,
      render: (customer) => customer.name,
      onCell,
    },
    {
      title: t('agreements.columns.customerRepresentative'),
      dataIndex: 'customer_representative',
      key: 'customer_representative',
      width: 200,
      render: (customerRepresentative) => customerRepresentative.name,
      onCell,
    },
    {
      title: t('agreements.columns.owner'),
      dataIndex: 'owner',
      key: 'owner',
      width: 200,
      render: (owner) => owner.name,
      onCell,
    },
    {
      title: t('agreements.columns.ownerRepresentative'),
      dataIndex: 'owner_representative',
      key: 'owner_representative',
      width: 200,
      render: (ownerRepresentative) => ownerRepresentative.name,
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      fixed: 'right',
      render: (_, record) => (
        <div className={style.actions}>
          <button
            type="button"
            onClick={() => {
              generateDocument(record.id)
            }}
            className={style.actions_item}
          >
            <FileTextOutlined />
          </button>
          <button
            type="button"
            onClick={() => {
              dispatch(setSendEmail({
                type: SendEmailType.Agreements,
                companyId: record.customer.id,
                id: record.id,
              }))
            }}
            className={style.actions_item}
          >
            <MailOutlined />
          </button>
          <Popconfirm
            placement="left"
            title={t('popconfirm.deleteTitle')}
            onConfirm={() => {
              deleteAgreement(record.id)
            }}
            okText={t('popconfirm.okText')}
            cancelText={t('popconfirm.cancelText')}
            disabled={isLoadingDelete}
          >
            <button
              type="button"
              className={cn(style.actions_item, style.actions_item__delete)}
              disabled={isLoadingDelete}
            >
              <DeleteOutlined />
            </button>
          </Popconfirm>
        </div>
      ),
    },
  ], [data?.meta.pageSize, deleteAgreement, dispatch, generateDocument, isLoadingDelete, onCell, paramsFilters.page, t])

  return {
    columns,
    data,
    isLoading,
    paramsFilters,
    setParamsFilters,
  }
}
