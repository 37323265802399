import { detailsApi } from '@src/store/services/details-service'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { serialize } from 'object-to-formdata'
import { Preloader } from '@src/components/Preloader/Preloader'
import { Form, Formik } from 'formik'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { Button } from 'antd'
import style from './detailsAddModal.module.scss'

type Props = {
  detailId: number
  onClose: () => void
  isRemove?: boolean
}

export const DetailsAddModal = ({ detailId, onClose, isRemove } : Props) => {
  const { t } = useTranslation()
  const [addDetail, { isLoading }] = detailsApi.useChangeDetailCountMutation()

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      count: Yup.number().min(1, ({ min }) => `${t('form.errors.min')} ${min}`).required(t('form.errors.required') ?? ''),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    count: 1,
  }), [])

  const onSubmit = async (values: any) => {
    const body = {
      detail_id: detailId,
      count: isRemove ? -values.count : values.count,
    }

    const formData = serialize(body, { indices: true })

    const result = await addDetail(formData)

    if ('data' in result) {
      onClose()
    }
  }

  return (
    <div>
      <Preloader loading={isLoading}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {() => (
            <Form className={style.formWrapper}>
              <FieldFormik
                styleInline={{ width: '100%' }}
                name="count"
                placeholder={t('details.columns.quantity') ?? ''}
                type="number"
              />

              <div className={style.buttonWrapper}>
                <Button
                  htmlType="button"
                  type="default"
                  onClick={onClose}
                  loading={isLoading}
                >
                  {t('details.close')}
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={isLoading}
                >
                  {isRemove ? t('details.remove_detail') : t('details.add_detail')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Preloader>
    </div>
  )
}
