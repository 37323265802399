import React, { ReactNode, useMemo } from 'react'
import { PageTable } from '@src/components/Table/PageTable'
import { useAgreementInvoicesTableHook } from '@src/components/pages/agreementInvoices/AgreementInvoicesTable.hook'
import { useAgreementOrderTableHook } from '@src/components/pages/agreements/AgreemetOrderTable.hook'
import { CloseAgreementOrderForm } from '../agreements/CloseAgreementOrderForm'

export const useCarsViewContentListHook = (serialNumber: string, id: number): Record<string, ReactNode> => {
  const {
    columns: columnsAgreementInvoices,
    paramsFilters: paramsFiltersAgreementInvoices,
    setParamsFilters: setParamsFiltersAgreementInvoices,
    isLoading: isLoadingAgreementInvoices,
    data: dataAgreementInvoices,
  } = useAgreementInvoicesTableHook({
    initParamsFilters: {
      page: 1,
      filters: {
        order: {
          car: {
            serial_number: {
              $eq: serialNumber,
            },
          },
        },
      },
    },
  })

  const {
    columns: columnsAgreementOrders,
    paramsFilters: paramsFiltersAgreementOrders,
    setParamsFilters: setParamsFiltersAgreementOrders,
    isLoading: isLoadingAgreementOrders,
    data: dataAgreementOrders,
    closeAgreementId,
    setCloseAgreementId,
  } = useAgreementOrderTableHook({
    initParamsFilters: {
      page: 1,
      filters: {
        car_id: {
          $eq: id,
        },
      },
    },
  })

  return useMemo(() => ({
    agreementOrders: (
      <div>
        <PageTable
          isTableOnly
          setParamsFilters={setParamsFiltersAgreementOrders}
          paramsFilters={paramsFiltersAgreementOrders}
          tableData={{
            ...dataAgreementOrders,
            isLoading: isLoadingAgreementOrders,
            columns: columnsAgreementOrders,
            components: {
              body: {
                row: (props: any) => (
                  <tr
                    {...props}
                    style={{
                      background: dataAgreementOrders?.data?.find((item: { id: any; }) => item.id === props['data-row-key'])?.order?.agreement.owner.color,
                    }}
                  />
                ),
              },
            },
          }}
        />
        <CloseAgreementOrderForm agreementOrderId={closeAgreementId} onClose={() => setCloseAgreementId(null)} />
      </div>
    ),
    agreementInvoices: (
      <PageTable
        isTableOnly
        setParamsFilters={setParamsFiltersAgreementInvoices}
        paramsFilters={paramsFiltersAgreementInvoices}
        tableData={{
          ...dataAgreementInvoices,
          isLoading: isLoadingAgreementInvoices,
          columns: columnsAgreementInvoices,
          components: {
            body: {
              row: (props: any) => (
                <tr
                  {...props}
                  style={{
                    background: dataAgreementInvoices?.data?.find((item: { id: any; }) => item.id === props['data-row-key'])?.order?.agreement.owner.color,
                  }}
                />
              ),
            },
          },
        }}
      />
    ),
  }), [
    columnsAgreementInvoices,
    dataAgreementInvoices, isLoadingAgreementInvoices,
    paramsFiltersAgreementInvoices,
    setParamsFiltersAgreementInvoices,
    columnsAgreementOrders,
    dataAgreementOrders, isLoadingAgreementOrders,
    paramsFiltersAgreementOrders,
    setParamsFiltersAgreementOrders,
    closeAgreementId,
    setCloseAgreementId,
  ])
}
