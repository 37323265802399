// import { useFormikContext } from 'formik'
// import { companiesApi } from '@src/store/services/companies-service'
import { usePrintableBlock } from '@src/hooks/usePrintableBlock'
import { Button } from 'antd'
import { PrinterOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { agreementsApi } from '@src/store/services/agreements-service'
import { carsApi } from '@src/store/services/cars-service'
import { Fragment } from 'react'
import moment from 'moment'
import { customerWorkersApi } from '@src/store/services/customerWorkers-service'

export const AgreementOrderDocument = () => {
  const { t } = useTranslation()
  const { values } = useFormikContext<any>()
  const { data: agreement } = agreementsApi.useGetOneAgreementQuery(values.agreement_id, { skip: !values.agreement_id })
  const { data: car } = carsApi.useGetOneCarQuery(values.car_id, { skip: !values.car_id })
  const { data: ownerRepresentative } = customerWorkersApi.useGetOneQuery(values.owner_representative_id, { skip: !values.owner_representative_id })
  const { data: customerRepresentative } = customerWorkersApi.useGetOneQuery(values.customer_representative_id, { skip: !values.customer_representative_id })

  usePrintableBlock('#printable-block')

  return (
    <>
      <div className="agreement_document" id="printable-block">
        <p className="font-bold" style={{ textAlign: 'center' }}>
          MECHANIZMŲ BEI ĮRANGOS NUOMOS SUTARTIS NR.:
          {' '}
          {agreement?.agreement_number}
          <br />
          SPECIALIOSIOS SĄLYGOS NR.
          {values?.agreementOrderNumber}
        </p>
        <table className="table">
          <tbody>
            <tr className="row">
              <td className="col">
                <p className="text">Nuomotojas</p>
              </td>
              <td className="col">
                <p className="text">
                  <strong>
                    {agreement?.owner?.name}
                  </strong>
                  {' '}
                  į.k.
                  {' '}
                  {agreement?.owner?.company_code}
                </p>
              </td>
            </tr>
            <tr className="row">
              <td className="col">
                <p className="text">
                  Nuomotojo buveinė / Įrangos perdavimo ir grąžinimo vieta
                </p>
              </td>
              <td className="col">
                <p className="text">
                  {agreement?.owner?.address}
                  {' '}
                  /
                  {' '}
                  {values.rent_place}
                </p>
              </td>
            </tr>
            <tr className="row">
              <td className="col">
                <p className="text">Nuomininkas</p>
              </td>
              <td className="col">
                <p className="text">
                  <strong>
                    {agreement?.customer.name}
                  </strong>
                  {' '}
                  į.k.
                  {' '}
                  {agreement?.customer.company_code}
                </p>
              </td>
            </tr>
            <tr className="row">
              <td className="col">
                <p className="text">Įranga</p>
              </td>
              <td className="col">
                <p className="text">
                  {car?.make}
                  {' '}
                  {car?.model}
                  , serijos numeris
                  {' '}
                  {car?.serial_number}
                </p>
              </td>
            </tr>
            {!!values?.equipment?.length && (
            <tr className="row">
              <td className="col">
                <p className="text">Papildoma komplektacija, kitos pastabos</p>
              </td>
              <td className="col">
                <p className="text">
                  {values.equipment.map((item: any, i: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={i}>
                      {item.name}
                      {' - '}
                      {item.value}
                      {' '}
                      EUR + PVM;
                      <br />
                    </Fragment>
                  ))}
                </p>
              </td>
            </tr>
            )}
            {!!values?.services?.length && (
            <tr className="row">
              <td className="col">
                <p className="text">Papildomos paslaugos</p>
              </td>
              <td className="col">
                <p className="text">
                  {values.services.map((item: any, i: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={i}>
                      {item.name}
                      {' - '}
                      {item.value}
                      {' '}
                      EUR + PVM;
                      <br />
                    </Fragment>
                  ))}
                </p>
              </td>
            </tr>
            )}
            <tr className="row">
              <td className="col">
                <p className="text">Įrangos vertė Sutarties sudarymo metu</p>
              </td>
              <td className="col">
                <p className="text">
                  {car?.car_value}
                  {' '}
                  EUR + PVM;
                </p>
              </td>
            </tr>
            <tr className="row">
              <td className="col">
                <p className="text">Minimalus nuomos mokestis</p>
              </td>
              <td className="col">
                <p className="text">
                  Minimalus nuomos mokestis
                  {' '}
                  {values?.minimum_rent}
                  {' '}
                  EUR + PVM už
                  {' '}
                  {values?.payment_term === 'days' && 'dieną'}
                  {values?.payment_term === 'hours' && 'valandą'}
                  {values?.payment_term === 'months' && 'mėnesį'}
                  {' '}
                  Nuomos sutartis, pageidaujant nuomininkui, gali būti pratęsta, pagal atskirą susitarimą ir sąlygas,
                  neribotam laikotarpiui.
                </p>
              </td>
            </tr>
            <tr className="row">
              <td className="col">
                <p className="text">Nuomos mokesčio mokėjimo terminas</p>
              </td>
              <td className="col">
                <p className="text">
                  Nuomininkas sumoka nuomos mokestį nuomotojui,
                  {' '}
                  {values?.payment_deadline}
                  , nuo sąskaitos faktūros išrašymo dienos.
                </p>
              </td>
            </tr>
            <tr className="row">
              <td className="col">
                <p className="text">Nuomos terminas</p>
              </td>
              <td className="col">
                <p className="text">
                  {values?.lease_term}
                  {' '}
                  {values?.payment_term === 'days' && 'd'}
                  {values?.payment_term === 'hours' && 'val'}
                  {values?.payment_term === 'months' && 'mėn'}
                </p>
              </td>
            </tr>
            <tr className="row">
              <td className="col">
                <p className="text">Nuomos teritorija</p>
              </td>
              <td className="col">
                <p className="text">
                  Lietuvos Respublikos teritorija. Esant poreikiui dirbti užsienyje, būtina iš anksto raštu informuoti
                  nuomotoją ir gauti jo raštišką leidimą.
                </p>
              </td>
            </tr>
            <tr className="row">
              <td className="col">
                <p className="text">Maksimalus moto darbo valandų kiekis per nuomos laikotarpį </p>
              </td>
              <td className="col">
                <p className="text">{values?.max_engine_hours}</p>
              </td>
            </tr>
            <tr className="row">
              <td className="col">
                <p className="text">Papildomos moto valandos kaina</p>
              </td>
              <td className="col">
                <p className="text">
                  {values?.engine_hour_cost}
                  {' '}
                  EUR + PVM
                </p>
              </td>
            </tr>
            <tr className="row" style={{ borderTop: '0.01em solid #000000' }}>
              <td className="col">
                <p className="text">
                  Įrangos stovis
                </p>
              </td>
              <td className="col">
                <p className="text">
                  Nuomos terminu Nuomininkas atsako už Įrangos sugadinimą ar praradimą arba Įrangai padarytą žalą,
                  įskaitant Įrangos gedimus, išskyrus tuos, kurie atsirado dėl normalaus nusidėvėjimo. Irangos darbų
                  kaštus, kurie reikalingi dėl įvykusių tyčinių sugadinimų (apdaužymų), atsitiktinių sugadinimų,
                  netinkamo valdymo ir priežiūros, gedimų, atsirandančių dėl akivaizdaus aplaidumo, durų stiklų,
                  priekinio
                  stiklo, žibintų pažeidimų ir kt., padangų defektų (nuleistos, supjaustytos padangos), guminių vikšrų
                  pažeidimų, padengia Nuomininkas. Nuomininkas įsipareigoja naudoti Įrangą tik pagal jos tiesioginę
                  paskirtį,
                  vadovaudamasis Įrangos naudojimo instrukcijomis, eksploatacinių technologijų reikalavimais ir
                  neviršijant jos naudojimo pajėgumų.
                </p>
              </td>
            </tr>
            <tr className="row">
              <td className="col">
                <p className="text">Draudimas</p>
              </td>
              <td className="col">
                <p className="text">Pagal Sutarties nuostatas.</p>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="signatures">
          <tbody>
            <tr className="row">
              <td className="col" style={{ verticalAlign: 'top' }}>
                <p className="text">
                  Nuomotojas:
                </p>
                <p className="text">
                  <strong>{agreement?.owner?.name}</strong>
                  <br />
                  Į.K.
                  {' '}
                  {agreement?.owner?.company_code}
                  <br />
                  PVM mokėjimo kodas:
                  {' '}
                  {agreement?.owner?.vat_number}
                  <br />
                  {agreement?.owner?.address}
                </p>
              </td>
              <td className="col" style={{ verticalAlign: 'top' }}>
                <p className="text">
                  Nuomininkas:
                </p>
                <p className="text">
                  <strong>{agreement?.customer?.name}</strong>
                  <br />
                  Į.K.
                  {' '}
                  {agreement?.customer?.company_code}
                  <br />
                  {agreement?.customer?.address}
                </p>
              </td>
            </tr>
            <tr className="row">
              <td className="col">
                <br />
                <br />
              </td>
              <td className="col">
                <br />
                <br />
              </td>
            </tr>
            <tr className="row">
              <td className="col">
                <span className="text" style={{ borderTop: '1px dotted #000000' }}>
                  {ownerRepresentative?.position || agreement?.owner_representative?.position}
                  <span style={{ display: 'inline-block', width: 56 }} />
                  {ownerRepresentative?.name || agreement?.owner_representative?.name}
                </span>
              </td>
              <td className="col">
                <span className="text" style={{ borderTop: '1px dotted #000000' }}>
                  {customerRepresentative?.position || agreement?.customer_representative?.position}
                  <span style={{ display: 'inline-block', width: 56 }} />
                  {customerRepresentative?.name || agreement?.customer_representative?.name}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="page-break" />
        <p className="font-bold" style={{ textAlign: 'center' }}>
          ĮRANGA
          {' '}
          {car?.make}
          {' '}
          {car?.model}
          {' '}
          PRIĖMIMO PERDAVIMO AKTAS
        </p>
        <p className="text" style={{ textAlign: 'center' }}>{moment(values.date_start).format('YYYY-MM-DD')}</p>
        <p className="text" style={{ textAlign: 'center' }}>Vilnius</p>
        <p className="text">
          <span style={{ display: 'inline-block', width: 16 }} />
          Šiuo aktu pažymime, kad įrangos
          {' '}
          {car?.make}
          {' '}
          {car?.model}
          , serijos numeris
          {' '}
          {car?.serial_number}
          , perduodamas iš įmonės
          {' '}
          <strong>{agreement?.owner?.name}</strong>
          (į.k.
          {' '}
          {agreement?.owner?.company_code}
          ), į įmonę
          <strong>{agreement?.customer?.name}</strong>
          (į.k.
          {' '}
          {agreement?.customer?.company_code}
          ) žinią ir atsakomybę. Įrangos valandų skaičius, perdavimo metu, yra
          {' '}
          {values?.engine_hours_start}
          {' '}
          moto valandos.
        </p>
        <p className="text">
          Nuomininkas informuotas, kad šiam įranga reikalingas kasdieninis tepimas.
        </p>
        <p className="text">
          Atiduodant įranga pastebėti defektai ar trūkumai:
        </p>
        <p style={{ borderBottom: '1px dotted #000000', height: 24, width: '100%' }} />
        <p style={{ borderBottom: '1px dotted #000000', height: 24, width: '100%' }} />
        <p style={{ borderBottom: '1px dotted #000000', height: 24, width: '100%' }} />
        <p className="text">
          Perdavė:
        </p>
        <p className="text" style={{ textAlign: 'center' }}>
          _________________________________________
          <br />
          (vardas ir pavardė, pareigos, antspaudas, parašas)
        </p>
        <p className="text">
          Priėmė:
        </p>
        <p className="text" style={{ textAlign: 'center' }}>
          _________________________________________
          <br />
          (vardas ir pavardė, pareigos, antspaudas, parašas)
        </p>
        <p className="text" />
        <div className="page-break" />
        <p className="font-bold" style={{ textAlign: 'center' }}>Technikos fotografijos perdavimo metu:</p>
        <p className="text" style={{ textAlign: 'center' }}>{moment(values.date_start).format('YYYY-MM-DD')}</p>
        <p className="text" style={{ textAlign: 'center' }}>Vilnius</p>
      </div>
      <div className="documentActions">
        <Button icon={<PrinterOutlined />} onClick={window.print}>{t('settings.print')}</Button>
      </div>
    </>
  )
}
