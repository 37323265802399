import { FC, ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Card } from 'antd'
import { tasksApi } from '@src/store/services/tasks-service'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { TaskInformation } from '@src/components/pages/tasks/TaskInformation'
import { TaskTimelogs } from '@src/components/pages/tasks/TaskTimelogs'
import { TaskRouteHistory } from '@src/components/pages/tasks/TaskRouteHistory'
import { TaskPhotoGallery } from '@src/components/pages/tasks/TaskPhotoGallery'
import { TaskEndWork } from '@src/components/pages/tasks/TaskEndWork/TaskEndWork'
import { TaskComments } from '@src/components/pages/tasks/TaskComments'
import { OldWorks } from '@src/components/pages/tasks/OldWorks'
import { TaskStatusEnum } from '@src/types/tasks'

type TaskViewProps = {
  readonly?: boolean
}

export const TaskArchiveView: FC<TaskViewProps> = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams<{ id: string }>()
  const [activeTabKey, setActiveTabKey] = useState<string>('information')
  const { data, isLoading } = tasksApi.useGetOneTaskQuery(+params.id!)
  const taskRouteHistoryRef = useRef<any>()
  const taskTimelogsRef = useRef<any>()

  useEffect(() => {
    setActiveTabKey('information')
  }, [params.id])

  const contentList = useMemo<Record<string, ReactElement>>(() => ({
    information: (
      <TaskInformation data={data!} />
    ),
    routeHistory: (
      <TaskRouteHistory taskId={+params.id} ref={taskRouteHistoryRef} />
    ),
    photoGallery: (
      <TaskPhotoGallery taskId={+params.id} images={data?.images!} />
    ),
    timelogs: (
      <TaskTimelogs taskId={+params.id} ref={taskTimelogsRef} workers={data?.workers || []} />
    ),
    endWork: (
      <TaskEndWork
        taskId={+params.id}
        taskStatus={data?.status!}
        setActiveTabKey={setActiveTabKey}
        details={[]}
        data={data!}
      />
    ),
    comments: (
      <TaskComments taskId={+params.id} comments={data?.comments!} />
    ),
    checkingAct: (
      <TaskEndWork
        taskId={+params.id}
        quiz={data?.quiz}
        taskStatus={data?.status!}
        setActiveTabKey={setActiveTabKey}
        details={data?.details || []}
        workDurationHours={data?.work_duration_hours}
        workDurationMinutes={data?.work_duration_minutes}
        data={data!}
      />
    ),
    oldWorks: (
      <OldWorks carId={data?.car_id!} taskId={+params.id} />
    ),
  }), [data, params.id])

  const tabList = useMemo(() => [
    {
      key: 'information',
      label: t('tasks.viewTabs.information'),
    },
    {
      key: 'photoGallery',
      label: t('tasks.viewTabs.photoGallery'),
    },
    {
      key: 'checkingAct',
      label: t('tasks.viewTabs.checkingAct'),
      disabled: data?.status !== TaskStatusEnum.Finished && data?.status !== TaskStatusEnum.Signed,
    },
    {
      key: 'comments',
      label: t('tasks.viewTabs.comments'),
    },
    {
      key: 'oldWorks',
      label: t('tasks.viewTabs.oldWorks'),
    },
  ], [data, t])

  return (
    <>
      <div>
        <Button
          icon={<ArrowLeftOutlined />}
          onClick={() => history.goBack()}
          type="link"
        >
          {t('common.goBack')}
        </Button>
      </div>
      <br />
      <Card
        title={data?.name}
        loading={isLoading}
        activeTabKey={activeTabKey}
        onTabChange={setActiveTabKey}
        tabList={tabList}
      >
        {data && contentList[activeTabKey]}
      </Card>
    </>
  )
}
