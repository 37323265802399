import { useTranslation } from 'react-i18next'
import { NavLink, useHistory, useParams } from 'react-router-dom'
import { Button, Card, Col, Input, Radio, Row } from 'antd'
import { Form, Formik } from 'formik'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import moment from 'moment/moment'
import cn from 'classnames'
import { Preloader } from '@src/components/Preloader/Preloader'
import { AppDispatch } from '@src/store/store'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { DatePickerFormik } from '@src/components/DatePickerFormik/DatePickerFormik'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import { RadioFormik } from '@src/components/RadioFormik/RadioFormik'
import inputStyle from '@src/components/FieldFormik/FieldFormik.module.scss'
import { companiesApi } from '@src/store/services/companies-service'
import { transportationApi } from '@src/store/services/transportation-service'
import { setSendEmail } from '@src/store/ducks/app/reducer'
import { SendEmailType } from '@src/types/mailLogs'
import { isString } from '@src/lib/utils'
import { TransportationBillStatus } from '@src/types/transportationBills'
import { TransportationBillsDocument } from '@src/components/pages/transportationBills/TransportationBillsDocument'
import style from './transportationBillsEdit.module.scss'

export const TransportationBillsEdit = () => {
  const { t } = useTranslation()
  const formRef = useRef<any>()
  const params = useParams<{ id: string }>()
  const dispatch = useDispatch<AppDispatch>()
  const history = useHistory()
  const { data: companiesSelect } = companiesApi.useGetSelectCompaniesQuery()
  const { data: info, isLoading: isLoadingGetOne } = transportationApi.useGetOneTransportationBillsQuery(+params.id)
  const [editTransportationBill, { isLoading: isLoadingEdit }] = transportationApi.useEditTransportationBillMutation()
  const [archiveTransportationBill, { isLoading: isLoadingArchive }] = transportationApi.useArchiveTransportationBillMutation()
  const [distance, setDistance] = useState(0)
  const [transportationRouteType, setTransportationRouteType] = useState(0)
  const [pricePerKm, setPricePerKm] = useState(0)
  const [transportationCost, setTransportationCost] = useState(0)

  useEffect(() => {
    if (info?.transportation?.distance) {
      setDistance(+info.transportation.distance)
    }
  }, [info])

  useEffect(() => {
    const cost = distance * pricePerKm

    setTransportationCost(Math.ceil(cost))
  }, [distance, pricePerKm])

  const validationSchema = useMemo(() => Yup.object().shape({
    bill: Yup.array().min(1).of(Yup.object().shape({
      name: Yup.string().required(t('form.errors.required') ?? ''),
      unit: Yup.number().required(t('form.errors.required') ?? ''),
      amount: Yup.number().required(t('form.errors.required') ?? ''),
      value: Yup.number().required(t('form.errors.required') ?? ''),
    })),
  }), [t])

  const initialValues = useMemo(() => ({
    status: TransportationBillStatus.processed,
    id: info?.id ?? '',
    bill: info?.bill ? JSON.parse(info?.bill) : ([] as any),
    bill_number: info?.bill_number ?? '',
    isCredit: info?.isCredit ?? 0,
    pay_by: info?.pay_by ? moment(info.pay_by) : '',
    date_closed: info?.date_closed ? moment(info.date_closed) : '',
    company_id: info?.company_id || '',
    transportation_id: info?.transportation_id || '',
  }), [info])

  const onSubmit = async (data: any) => {
    let result

    if (data.status === TransportationBillStatus.processed) {
      result = await editTransportationBill({
        id: +params.id,
        body: {
          ...data,
          pay_by: data.pay_by ? data.pay_by.format('YYYY-MM-DD') : '',
          date_closed: data.date_closed ? data.date_closed.format('YYYY-MM-DD') : '',
          bill: JSON.stringify(data.bill),
          sum: data.bill.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.value * currentValue.amount, 0),
        },
      })
    } else {
      result = await archiveTransportationBill({
        id: +params.id,
        body: {
          ...data,
          pay_by: data.pay_by ? data.pay_by.format('YYYY-MM-DD') : '',
          date_closed: data.date_closed ? data.date_closed.format('YYYY-MM-DD') : '',
          bill: JSON.stringify(data.bill),
          sum: data.bill.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.value * currentValue.amount, 0),
        },
      })
    }

    if ('data' in result) {
      if (data.company_id && data.bill_number && data.company_id) {
        dispatch(setSendEmail({
          type: SendEmailType.TransportationBills,
          companyId: data.company_id,
          id: +params.id,
        }))
      }
      history.goBack()
    }
  }

  return (
    <div>
      <h1 className="page_title">
        {t('transportationBillsEdit.title')}
      </h1>

      <Preloader loading={isLoadingGetOne}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
          innerRef={formRef}
        >
          {({ values, setFieldValue, errors }) => (
            <Form className={style.form} style={{ background: 'transparent', padding: 0 }}>
              <Row gutter={[24, 24]}>
                <Col xl={24} xxl={12}>
                  <Card title={t('transportationCreate.paymentInfoBlock')}>
                    <Row gutter={[20, 0]}>
                      {values.bill.map((item: any, i: any) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Fragment key={i}>
                          <Col xs={24} md={5}>
                            <FieldFormik
                              name={`bill[${i}].name`}
                              placeholder={t('transportationCreate.customCargoName') ?? ''}
                            />
                          </Col>
                          <Col xs={24} md={5}>
                            <FieldFormik
                              name={`bill[${i}].unit`}
                              type="number"
                              placeholder={t('transportationCreate.customCargoUnit') ?? ''}
                            />
                          </Col>
                          <Col xs={24} md={5}>
                            <FieldFormik
                              name={`bill[${i}].amount`}
                              type="number"
                              placeholder={t('transportationCreate.customCargoAmount') ?? ''}
                            />
                          </Col>
                          <Col xs={24} md={5}>
                            <FieldFormik
                              name={`bill[${i}].value`}
                              type="number"
                              placeholder={t('transportationCreate.customBillValue') ?? ''}
                            />
                          </Col>
                          <Col xs={24} md={2}>
                            {values.bill.length > 1 && (
                              <Button
                                size="large"
                                onClick={() => {
                                  setFieldValue('bill', values.bill.filter((_: any, filterI: number) => filterI !== i))
                                }}
                              >
                                Delete
                              </Button>
                            )}
                          </Col>
                        </Fragment>
                      ))}
                      {errors.bill && isString(errors.bill) && (
                        <Col>
                          <span style={{ color: 'red' }}>{errors.bill.toString()}</span>
                        </Col>
                      )}

                      <Col span={24}>
                        <Button
                          className={style.btn}
                          onClick={() => {
                            setFieldValue('bill', [...values.bill, { name: '', unit: '', amount: '', value: '' }])
                          }}
                        >
                          {t('transportationCreate.addBill')}
                        </Button>
                      </Col>
                      <Col xs={24} md={12}>
                        <FieldFormik
                          name="bill_number"
                          placeholder={t('transportationCreate.billNumber') ?? ''}
                        />
                      </Col>
                      <Col xs={24} md={12}>
                        <SelectFormik
                          name="company_id"
                          showSearch
                          placeholder={t('transportationBillsEdit.company') ?? ''}
                          options={companiesSelect?.map((item) => ({ label: item.name, value: item.id })) || []}
                        />
                      </Col>
                      <Col xs={24} md={12}>
                        <DatePickerFormik name="pay_by" placeholder={t('transportationCreate.payBy') ?? ''} />
                      </Col>
                      <Col xs={24} md={12}>
                        <DatePickerFormik
                          name="date_closed"
                          placeholder={t('transportationBillsEdit.dateClosed') ?? ''}
                        />
                      </Col>
                      <Col xs={24} md={12}>
                        <div style={{ marginBottom: 15 }}>
                          <span style={{ marginRight: 15 }}>{t('transportationCreate.type')}</span>
                          <RadioFormik
                            options={[
                              { value: 0, label: t('transportationCreate.basic') },
                              { value: 1, label: t('transportationCreate.credit') },
                            ]}
                            name="isCredit"
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className={style.actions}>
                      <Button
                        htmlType="submit"
                        onClick={() => { setFieldValue('status', TransportationBillStatus.processed) }}
                        type="primary"
                        disabled={isLoadingArchive}
                        loading={isLoadingEdit}
                      >
                        {t('transportationBillsEdit.submit')}
                      </Button>
                      <Button
                        htmlType="submit"
                        onClick={() => { setFieldValue('status', TransportationBillStatus.archived) }}
                        type="primary"
                        disabled={isLoadingEdit}
                        loading={isLoadingArchive}
                      >
                        {t('transportationBillsEdit.toArchive')}
                      </Button>
                      <NavLink
                        to={`/transportation/edit/${info?.transportation_id}`}
                      >
                        <Button>
                          {t('transportationCreate.openTransportation')}
                        </Button>
                      </NavLink>
                    </div>
                  </Card>
                  <Card title={t('transportationBillsEdit.costCalculator')}>
                    <div className={style.wrapperCalculator}>
                      <p>
                        {t('transportationCreate.addressFrom')}
                        {': '}
                        {info?.transportation?.address_from}
                      </p>
                      <p>
                        {t('transportationCreate.addressTo') ?? ''}
                        {': '}
                        {info?.transportation?.address_to}
                      </p>
                      <div>
                        <div className={inputStyle.wrapper} style={{ margin: 0 }}>
                          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                          <label className={inputStyle.label}>
                            <Input
                              value={distance}
                              type="number"
                              onChange={(e) => setDistance(+e.target.value)}
                              size="large"
                            />
                            <div
                              className={
                                cn(
                                  inputStyle.placeholder,
                                  distance && inputStyle.placeholder_active,
                                )
                              }
                            >
                              {t('transportationCreate.distance')}
                            </div>
                          </label>
                        </div>
                        <Radio.Group
                          options={[
                            { value: 0, label: t('transportationBillsEdit.oneWay') },
                            { value: 1, label: t('transportationBillsEdit.return') },
                          ]}
                          value={transportationRouteType}
                          onChange={(e) => {
                            const isReturn = !!e.target.value

                            if (isReturn) setDistance(distance * 2)
                            else setDistance(+(info?.transportation.distance || 0))

                            setTransportationRouteType(e.target.value)
                          }}
                        />
                      </div>
                      <div className={inputStyle.wrapper} style={{ margin: 0 }}>
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label className={inputStyle.label}>
                          <Input
                            value={pricePerKm}
                            type="number"
                            onChange={(e) => setPricePerKm(+e.target.value)}
                            size="large"
                          />
                          <div
                            className={
                              cn(
                                inputStyle.placeholder,
                                distance && inputStyle.placeholder_active,
                              )
                            }
                          >
                            {t('transportationBillsEdit.pricePerKilometer')}
                          </div>
                        </label>
                      </div>
                      <p>
                        {t('transportationBillsEdit.transportationCost')}
                        :
                        {transportationCost}
                        {' '}
                        €
                      </p>
                      <Button
                        htmlType="button"
                        type="primary"
                        onClick={() => {
                          formRef.current.setFieldValue('bill[0].value', transportationCost)
                        }}
                      >
                        {t('transportationBillsEdit.submit')}
                      </Button>
                    </div>
                  </Card>
                </Col>
                <Col xl={24} xxl={12}>
                  <Card>
                    <TransportationBillsDocument />
                  </Card>
                </Col>

              </Row>
            </Form>
          )}
        </Formik>
      </Preloader>
    </div>
  )
}
