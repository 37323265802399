import { PageTable } from '@src/components/Table/PageTable'
import useQuery from '@src/hooks/useQuery'
import { tasksApi } from '@src/store/services/tasks-service'
import { TaskType } from '@src/types/tasks'
import { ColumnProps } from 'antd/es/table'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

type Props = {
  detailId: number
}

export const DetailsHistory = ({ detailId }: Props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const pageQuery = useQuery('page')
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
    filters: {
      details: {
        'details.id': {
          $eq: detailId,
        },
      },
    },
  })
  const { data, isLoading } = tasksApi.useGetTasksListQuery(paramsFilters)

  const onCell = useCallback((record: TaskType) => ({
    onClick: () => {
      history.push(`/tasks/view/${record.id}`)
    },
  }), [history])

  const columns = useMemo<ColumnProps<TaskType>[]>(() => [
    {
      title: t('tasks.columns.workType'),
      dataIndex: 'work_type',
      key: 'work_type',
      render: (workType) => workType.name,
      width: '200px',
      onCell,
    },
    {
      title: t('tasks.columns.name'),
      dataIndex: 'name',
      key: 'name',
      width: '200px',
      onCell,
    },
    {
      title: t('tasks.columns.client'),
      dataIndex: 'client',
      key: 'client',
      render: (client) => client?.name,
      onCell,
    },
    {
      title: t('tasks.columns.car'),
      dataIndex: 'car',
      key: 'car',
      render: (car) => `${car.make}/${car.model}(${car.serial_number})`,
      onCell,
    },
  ], [t, onCell])

  return (
    <PageTable
      setParamsFilters={setParamsFilters}
      paramsFilters={paramsFilters}
      tableData={{
        ...data,
        isLoading,
        columns,
      }}
      isTableOnly
    />
  )
}
